import { getUserLanguage } from 'Lib/Localization'
import { AnalyticEvents } from 'Lib/Constants'
import { mapPrivacyPolicyLink, mapTermsOfUseLink } from 'Lib/LinkUtils'
import { RouteNames } from 'RouteNames'
import { images } from 'images'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Logo from './Logo'

const Container = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
`

const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.medium};
`

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 50px 0;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.text};

  p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  h1 {
    text-align: center;
  }

  small {
    text-align: center;
    margin-top: 40px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightText};
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    font-size: 12px;
  }
`
const GenderOption = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(40vw);
  height: calc(40vw);
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: none;

  &:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
`

const GenderImage = styled.img`
  width: 120%;
  height: 120%;
  object-fit: cover;
`

const TextWrapper = styled.div`
  position: absolute;
  z-index: 1;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: center;
  color: ${({ theme }) => theme.colors.lightText};
  bottom: 10%;
`

const LogoContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.theme.spacing.medium};
`

const LoginButtonWrapper = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  
  p {
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    position: absolute;
  }
`

interface GenderSelectStepProps {
  onSelectionChange: (gender: IGender) => void
}

const GenderSelectStep: React.FC<GenderSelectStepProps> = ({ onSelectionChange }) => {
  const { t } = useTranslation()
  const lang = getUserLanguage()
  const history = useHistory()

  const handleSelection = (gender: IGender) => {
    onSelectionChange(gender)
  }

  const handleLoginBtn = () => {
    logEvent(AnalyticEvents.WEB_SIGNIN_BUTTON_PRESSED)
    history.push(RouteNames.LOGIN)
  }

  const termsOfUseUrl = mapTermsOfUseLink(lang)
  const privacyUrl = mapPrivacyPolicyLink(lang)

  return (
    <Container>
      <Logo />
      <LoginButtonWrapper>
        <p
          onClick={handleLoginBtn}
        >
          {t('loginScreen.nextButtonLabel')}
        </p>
      </LoginButtonWrapper>
      <IntroductionContainer>
        <h1>{t('genderSelection.title')}</h1>
        <p>{t('genderSelection.subtitle')}</p>
        <GenderSelectWrapper>
          <GenderOption onClick={() => handleSelection('m')}>
            <GenderImage src={images.genderOptionMale} alt="Male" />
            <TextWrapper>{t('genderSelection.male')}</TextWrapper>
          </GenderOption>
          <GenderOption onClick={() => handleSelection('f')}>
            <GenderImage src={images.genderOptionFemale} alt="Female" />
            <TextWrapper>{t('genderSelection.female')}</TextWrapper>
          </GenderOption>
        </GenderSelectWrapper>
        <small>{t('genderSelection.disclaimerPart1')}
          <a href={termsOfUseUrl} target='_self'> {t('termsOfUse')} </a>
          {t('genderSelection.disclaimerPart2')}
          <a href={privacyUrl} target='_self'>{t('privacyPolicy')}</a >
          {t('genderSelection.disclaimerPart3')}
        </small>
      </IntroductionContainer>
    </Container>
  )
}

export default GenderSelectStep
