export interface SingleSelectOption {
  id: string
  label: string
  ariaLabel?: string
  maleImage?: string
  femaleImage?: string
  emoji?: string
}

export interface SingleSelectStepConfig {
  id: ONBOARDING_STEPS
  type: 'single-select'
  wordings: {
    title: string
  }
  options: SingleSelectOption[]
}

export interface MultipleSelectOption {
  id: string
  label: string
  ariaLabel?: string
  subtitle?: string
  maleImage?: string
  femaleImage?: string
  emoji?: string
}

export interface MultipleSelectStepConfig {
  id: ONBOARDING_STEPS
  type: 'multiple-select'
  wordings: {
    title: string
  }
  options: MultipleSelectOption[]
}

export type StepConfig = SingleSelectStepConfig | MultipleSelectStepConfig

export interface Step {
  id: ONBOARDING_STEPS
  type: 'single-select' | 'multiple-select' | 'height' | 'weight' | 'age' | 'custom' | 'information' | 'email'
  allowNext?: boolean
  allowSkip?: boolean
  stickyButton?: boolean
  wordings: {
    title: string
    subtitle?: string | null
    placeholder?: string | null
    info?: {
      title: string
      content: string
      color?: string
      icon?: any
    }
  }
  options?: Array<SingleSelectOption | MultipleSelectOption>
}

export type Selections = {
  [step in ONBOARDING_STEPS]?: any
}

export enum PackageTagId {
  BestValue = 'best-value',
  MostPopular = 'most-popular',
  FastTrack = 'fast-track',
  Trial = 'trial'
}

export enum BadgePackageTagId {
  None ='none',
  BestValue = 'best-value',
  MostPopular = 'most-popular',
  FastTrack = 'fast-track'
}

export type PackageOrderStyle = 'original' |'reversed'

export interface CustomPackage {
  id: string
  name: string
  price: number
  priceInCents: number
  pricePerDay: number
  numberOfPeriods: number
  discountedPrice?: number
  discountedPriceInCents?: number
  discountedPricePerDay?: number
  interval: string
  currency: string
  tag?: string
  tagId?: PackageTagId
}

export type PaymentColorScheme = 'blue' | 'green' | 'orange' | 'classic' | 'original'

export type PromotionLabelStyle = 'original' | 'mostPopularFastTrack'

export interface PaymentColorPackage {
  bannerColor: string
  packageColorDark: string
  packageColorLight: string
  selectedPriceColor: string
  selectedPriceTextColor: string
  selectedLeftRoundedArrow: string
  selectedLeftRoundedArrowLight: string
  scale: string
  statusLow: string
  statusMedium: string
  bannerTextColor: string
  buttonColor: string
}

export type ONBOARDING_STEPS =
  'gender' |
  'age' |
  'bad_habits' |
  'bmi' |
  'breakfast_time' |
  'current_body_type' |
  'desired_body_type' |
  'dinner_time' |
  'email' |
  'existing_email_login' |
  'fasting_feelings' |
  'fasting_knowledge' |
  'fasting_motivation' |
  'goals' |
  'height' |
  'home_or_outside' |
  'last_happy_weight' |
  'lunch_time' |
  'out_of_breath' |
  'perfect_weight' |
  'plan_is_ready' |
  'progress' |
  'receive_email' |
  'reinforcement_before_after' |
  'reinforcement_scientific' |
  'sleep_quality' |
  'special_occasion' |
  'special_occasion_date' |
  'target_zones' |
  'the_one_plan' |
  'tracked_hours' |
  'walking_time' |
  'water_intake' |
  'weight' |
  'work_activity_level' |
  'workload_frequency' |
  'age_range'
