// IMPORTANT: I (Oscar) modified the script a bit to make typescript happy (and also provide typesafety)
// it no longer injects itself on the window object, but anyways, it's dumb AF, it is just a string builder

// it parses some of the URL query params but it's so retarded it could only do it from the actual browser URL
// I gave it the the brains to use a "originalURL" to avoid having to pass thousands params around

// P.S. also fixed retarded stuff like returning null on a constructor

interface IOneLinkUrlGeneratorProps {
  originalURL?: string | null
  oneLinkURL: string
  pidKeysList?: string[]
  pidStaticValue?: string | null
  campaignKeysList?: string[]
  campaignStaticValue?: string | null
  pidOverrideList?: string[]
  gclIdParam?: string
}

export class OneLinkUrlGenerator {
  originalURL: string | null
  oneLinkURL: string
  pidOverrideList: string[]
  pidStaticValue: string | null
  gclIdParam: string
  pidKeysList: string[]
  campaignKeysList: string[]
  campaignStaticValue: string | null

  campaign: string | null
  mediaSource: string

  afParams: any

  constructor ({
    originalURL = null,
    oneLinkURL,
    pidKeysList = [],
    pidStaticValue = null,
    campaignKeysList = [],
    campaignStaticValue = null,
    pidOverrideList = [],
    gclIdParam = 'af_sub5'
  }: IOneLinkUrlGeneratorProps) {
    console.debug('Constructing OneLink URL generator')
    if (oneLinkURL === undefined || typeof oneLinkURL !== 'string' || oneLinkURL === '') {
      throw new Error('OneLinkUrlGenerator: oneLinkURL arg invalid')
    }

    this.originalURL = originalURL
    this.oneLinkURL = oneLinkURL
    this.pidOverrideList = pidOverrideList
    this.gclIdParam = gclIdParam
    this.pidKeysList = pidKeysList
    this.pidStaticValue = pidStaticValue
    this.campaignKeysList = campaignKeysList
    this.campaignStaticValue = campaignStaticValue

    // OneLink parameters
    this.campaign = getCampaignValue(this.originalURL, this.campaignKeysList, this.campaignStaticValue)
    this.mediaSource = getMediaSourceValue(this.originalURL, this.pidKeysList, this.pidStaticValue, this.pidOverrideList)

    // af_js_web=true will be added to every URL that was generated through this script
    this.afParams = { af_js_web: 'true' }
  }

  generateUrl () {
    if (this.mediaSource == null) {
      console.debug('No valid pid value was found. URL will no be changed')
      return null
    }

    // User was redirected using af_r parameter on an AppsFlyer attribution link
    if (getParameterFromURL(this.originalURL, 'af_redirect')) {
      console.debug('This user comes from AppsFlyer by redirection and is ready to be attributed. \nKeep direct app store links.')
      return null // in this case, the original store links in the install buttons stay the same
    }

    if (isFacebook()) {
      console.debug("This user comes from a paid Facebook ad - don't do anything. \nKeep direct app store links.")
      // the caller should make sure a return value of null will leave the original link
      return null
    }

    // Google Ads
    const pidValue = this.mediaSource
    const gclIdValue = getParameterFromURL(this.originalURL, 'gclid')

    if (gclIdValue) {
      this.afParams[this.gclIdParam] = gclIdValue
      console.debug('This user comes from Google AdWords')

      const kwValue = getParameterFromURL(this.originalURL, 'keyword')
      if (kwValue) {
        this.afParams.af_keywords = kwValue
        console.debug('There is a keyword associated with the ad')
      }
      // Other SRNs, custom networks and organic installs
    } else {
      console.debug('This user comes from SRN or custom network ')
    }
    // eslint-disable-next-line
    const finalURL = this.oneLinkURL + '?pid=' + pidValue + '&c=' + this.campaign + stringifyAfParameters(this.afParams)
    console.debug(`Generated OneLink URL ${finalURL}`)
    return finalURL
  }

  // Setters for AF params
  setDeepLinkValue (deepLinkValueParam: string, deepLinkValue: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'deep_link_value', deepLinkValueParam, deepLinkValue)
  }

  setChannel (channelParam: string, channelValue: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_channel', channelParam, channelValue)
  }

  setAdset (adsetParam: string, adsetValue: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_adset', adsetParam, adsetValue)
  }

  setAd (adParam: string, adValue: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_ad', adParam, adValue)
  }

  setAfSub1 (afSub1Param: string, afSub1Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_sub1', afSub1Param, afSub1Value)
  }

  setAfSub2 (afSub2Param: string, afSub2Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_sub2', afSub2Param, afSub2Value)
  }

  setAfSub3 (afSub3Param: string, afSub3Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_sub3', afSub3Param, afSub3Value)
  }

  setAfSub4 (afSub4Param: string, afSub4Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_sub4', afSub4Param, afSub4Value)
  }

  setAfSub5 (afSub5Param: string, afSub5Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'af_sub5', afSub5Param, afSub5Value)
  }

  // For new users the UDL method only returns parameters relevant to deferred deep linking: deep_link_value and deep_link_sub1-10
  // https://dev.appsflyer.com/hc/docs/dl_android_unified_deep_linking

  setAFDeepLinkSub1 (deepLinkSub1Param: string, deepLinkSub1Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'deep_link_sub1', deepLinkSub1Param, deepLinkSub1Value)
  }

  setAFDeepLinkSub2 (deepLinkSub2Param: string, deepLinkSub2Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'deep_link_sub2', deepLinkSub2Param, deepLinkSub2Value)
  }

  setAFDeepLinkSub3 (deepLinkSub3Param: string, deepLinkSub3Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'deep_link_sub3', deepLinkSub3Param, deepLinkSub3Value)
  }

  setAFDeepLinkSub4 (deepLinkSub4Param: string, deepLinkSub4Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'deep_link_sub4', deepLinkSub4Param, deepLinkSub4Value)
  }

  setAFDeepLinkSub5 (deepLinkSub5Param: string, deepLinkSub5Value: string | null = null) {
    setGenericParameter(this.originalURL, this.afParams, 'deep_link_sub5', deepLinkSub5Param, deepLinkSub5Value)
  }

  setCustomParameter (searchKey: string, customKey: string, customValue = null) {
    setGenericParameter(this.originalURL, this.afParams, customKey, searchKey, customValue)
  }
}

// Statis state-less functions
// Note - when device ID sharing becomes optional stop calling this method (or always return false)
function isFacebook () {
  if (document.referrer && document.referrer !== '') {
    return document.referrer.toLowerCase().includes('facebook')
  } else {
    return false
  }
}

function getParameterFromURL (originalUrl: string | null, name: string) {
  const url = originalUrl ?? window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  var results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function getMediaSourceValue (originalUrl: string | null, pidKeysList: any, pidStaticValue: any, pidOverrideList: any) {
  let pidValue = null

  for (const pidKey of pidKeysList) {
    if (pidKey != null && getParameterFromURL(originalUrl, pidKey)) {
      pidValue = getParameterFromURL(originalUrl, pidKey)
    }
  }

  if (pidValue != null) {
    // eslint-disable-next-line no-prototype-builtins
    if (pidOverrideList.hasOwnProperty(pidValue)) { pidValue = pidOverrideList[pidValue] }
  } else {
    pidValue = pidStaticValue
  }
  return pidValue
}

function getCampaignValue (originalUrl: string | null, campaignKeysList: string[], campaignStaticValue: string | null) {
  for (const campaignKey of campaignKeysList) {
    if (getParameterFromURL(originalUrl, campaignKey)) {
      return getParameterFromURL(originalUrl, campaignKey)
    }
  }

  if (campaignStaticValue != null) {
    return campaignStaticValue
  }

  if (document.getElementsByTagName('title')[0]) {
    return document.getElementsByTagName('title')[0].innerText
  }
  return 'unknown'
}

// Create a string of param and value from
function stringifyAfParameters (afParams: string[]) {
  let finalStr = ''

  for (var key of Object.keys(afParams)) {
    // console.debug(key + '->' + afParams[key])
    // @ts-expect-error
    if (afParams[key] != null) {
      // @ts-expect-error
      finalStr += `&${key}=${afParams[key]}`
    }
  }
  return finalStr
}

function setGenericParameter (originalUrl: string | null, afParams: string[], oneLinkParam: string, searchKey: string, newParamValue: string | null = null) {
  const searchKeyResult = getParameterFromURL(originalUrl, searchKey)
  if (searchKeyResult) {
    // @ts-expect-error
    afParams[oneLinkParam] = searchKeyResult
    console.debug(`${searchKey} found. ${oneLinkParam} = ${searchKeyResult}`)
  } else {
    if (newParamValue != null) {
      // @ts-expect-error
      afParams[oneLinkParam] = newParamValue
      console.debug(`${searchKey} not found. ${oneLinkParam} = ${newParamValue}`)
    } else {
      console.debug(`${searchKey} not found and newParamValue is null. Skipping.`)
    }
  }
}

// (function () {
//   window.AF = Object.assign((window.AF || {}), { OneLinkUrlGenerator: OneLinkUrlGenerator })
// })()
