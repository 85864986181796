// src/components/ParameterJanitor.tsx
import { removeSearchParamsFromLocalStorage } from 'Lib'
import { ActionType, Selectors } from 'Reducers'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** the next line are executed in global scope at startup */
const INITIAL_SEARCH_PATH = location.search
// strict null check -> empty string is also valid original search params
if (localStorage.getItem('originalSearchParams') === null) {
  localStorage.setItem('originalSearchParams', INITIAL_SEARCH_PATH)
  // This is where the localStorage is populated for the first time. (Even if there is not search parameters)
  // We need to save the time of this first visit.
  localStorage.setItem('firstVisit', new Date().toISOString())
}

const PARAMS_TO_REMOVE = [
  'appState',
  'tokenId',
  'token',
  'previousStep',
  'lastSavedStep',
  'lastSavedHistoryLength',
  'payment_intent',
  'payment_intent_client_secret',
  'selectedPackageId',
  'subscriptionId',
  'send_event',
  'redirect_status',
  'source'
]

/** URLParamSync is responsible for syncing the URL parameters with the redux store.
 *  Ideally all search parameters will be mapped to the relevant redux state and accessed from the necessary
 *  components via useSelector.
 *
 * Currently this component sanitises parameters using the old method while introducing the new method through the
 * Discount params.
*/
const URLParamSync = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const removeAllSearchParamsFromURL = () => {
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
    window.history.replaceState({ path: newUrl }, '', newUrl)
  }

  useEffect(() => {
    dispatch({ type: ActionType.FETCH_DISCOUNT_SEARCH_PARAMS })

    removeSearchParamsFromLocalStorage(PARAMS_TO_REMOVE)
    removeAllSearchParamsFromURL()
  }, [location.search, dispatch])

  return null
}

export default URLParamSync
