import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getDinnerTime(t: TFunction): Step {
  const dinnerTime = 'dinner_time'
  const before5pm = 'before5pm'
  const between5and7 = 'between5and7'
  const after7 = 'after7'
  const skip = 'skip'

  return {
    id: dinnerTime,
    type: 'single-select',
    wordings: {
      title: t(`${dinnerTime}.${title}`)
    },
    options: [
      {
        id: before5pm,
        label: t(`${dinnerTime}.${options}.${before5pm}`),
        emoji: '🕔'
      },
      {
        id: between5and7,
        label: t(`${dinnerTime}.${options}.${between5and7}`),
        emoji: '🕕'
      },
      {
        id: after7,
        label: t(`${dinnerTime}.${options}.${after7}`),
        emoji: '🕖'
      },
      {
        id: skip,
        label: t(`${dinnerTime}.${options}.${skip}`),
        emoji: '❌'
      }
    ]
  }
}
