import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getOutOfBreath(t: TFunction): Step {
  const outOfBreath = 'out_of_breath'
  const cantTalk = 'cant_talk'
  const canTalk = 'can_talk'
  const ok = 'ok'
  const easy = 'easy'

  return {
    id: outOfBreath,
    type: 'single-select',
    wordings: {
      title: t(`${outOfBreath}.${title}`)
    },
    options: [
      {
        id: cantTalk,
        label: t(`${outOfBreath}.${options}.${cantTalk}`),
        emoji: '😮‍💨'
      },
      {
        id: canTalk,
        label: t(`${outOfBreath}.${options}.${canTalk}`),
        emoji: '🗣'
      },
      {
        id: ok,
        label: t(`${outOfBreath}.${options}.${ok}`),
        emoji: '👍🏻'
      },
      {
        id: easy,
        label: t(`${outOfBreath}.${options}.${easy}`),
        emoji: '🚀'
      }
    ]
  }
}
