import { AnalyticEvents } from 'Lib/Constants'

const PIXEL_ID = 'o2tn3'
const CONVERSION_EVENT_ID = 'tw-o2tn3-oh2un'
const TWITTER_SCRIPT_SRC = 'https://static.ads-twitter.com/uwt.js'

interface IPurchaseInfo {
  conversion_id: string // For deduplication. See https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
  currency: string
  value: number
  items?: Object[]
  description: string
  status: 'started' | 'completed' // See "Event Parameters" section in https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
  twclid?: string | null
}

export interface ITwitterService {
  logPurchase: (info: IPurchaseInfo) => void
  // logEvent: (eventName: string, params?: Record<string, any>) => void
  // setPageData: (path: string, location: string) => void
  // pageView: (path: string, location: string) => void
  // setParams: (params: Object) => void
}

export const initTwitterService = () => {
  if (__DEV__) {
    return
  }

  /* ------------------ prepare script -------------------- */

  const head = document.querySelector('head')
  const twitterScript = document.createElement('script')
  twitterScript.type = 'text/javascript'
  twitterScript.async = true
  twitterScript.src = TWITTER_SCRIPT_SRC
  head?.appendChild(twitterScript) // This one runs the script

  /* ------------------ prepare event tracker -------------------- */

  if (!window.twq) {
    const twitterEventTracker: any = (window.twq = (...args: any) => {
      twitterEventTracker.exe
        ? twitterEventTracker.exe.apply(twitterEventTracker, args)
        : twitterEventTracker.queue.push(args)
    })
    twitterEventTracker.version = '1.1'
    twitterEventTracker.queue = []

    // The following part isn't different from what we do in "prepare script section"
    // and removing it didn't create any issues. I am keeping it here in case it serves a purpose.
    const twitterScriptElement = document.createElement('script')
    twitterScriptElement.async = true
    twitterScriptElement.src = TWITTER_SCRIPT_SRC
    const script: any = document.getElementsByTagName('script')[0]
    script.parentNode.insertBefore(twitterScriptElement, script) // This one also runs the same script.
  }

  /* ------------------ set PIXEL ID for Twitter -------------------- */

  if (window.twq) {
    window.twq('config', PIXEL_ID)
  } else {
    logEvent(AnalyticEvents.WEB_TWITTER_SERVICE_NOT_INITIALIZED)
  }
}

export const revokeTwitterConsent = () => {
  if (__DEV__) {
    return
  }
  var existingScript = document.querySelector('script[src="' + TWITTER_SCRIPT_SRC + '"]')
  if (existingScript) {
    existingScript.remove() // Remove script element from DOM
  }
}

export const createTwitterService = (): ITwitterService | null => {
  if (__DEV__) {
    return null
  }

  const twitterService = {
    logPurchase: (info: IPurchaseInfo) => {
      if (!window.twq) {
        return
      }
      window.twq('event', CONVERSION_EVENT_ID, info)
    }
  }

  return twitterService
}
