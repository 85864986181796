import * as Sentry from '@sentry/react'
import Loading from 'Components/Loading.component'
import { getSearchParams } from 'Lib'
import { AnalyticEvents } from 'Lib/Constants'
import { ActionType } from 'Reducers'
import { RouteNames } from 'RouteNames'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'store'
import { useHistory } from 'react-router-dom'

const TokenLoginContainer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const searchParams = getSearchParams()
    const futoken = searchParams.get('futoken')

    if (!futoken) {
      logEvent(AnalyticEvents.WEB_FUTOKEN_INVALID)
      Sentry.captureException(new Error(`TokenLogin received an invalid futoken ${searchParams.toString()}`))
      history.push(RouteNames.LOGIN)
      return
    }

    dispatch({
      type: ActionType.SIGN_IN_WITH_FUTOKEN_REQUEST,
      futoken,
      onError: (e: Error) => {
        logEvent(AnalyticEvents.WEB_FUTOKEN_ERROR, { error: e.message })
        Sentry.captureException(new Error(`There was a problem logging in with futoken ${searchParams.toString()}, ${e.message}`))
        history.push(RouteNames.LOGIN)
      }
    })
  }, [])

  return (
    <Loading />
  )
}

export default TokenLoginContainer
