import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getTargetZones(t: TFunction, gender?: IGender): Step {
  const targetZones = 'target_zones'

  const legsAndGlutes = 'legs_and_glutes'
  const chest = 'chest'
  const armsAndShoulders = 'arms_and_shoulders'
  const abs = 'abs'
  const fullBody = 'full_body'
  const legs = 'legs'
  const glutes = 'glutes'
  const belly = 'belly'

  if (gender === 'm') {
    return {
      id: targetZones,
      type: 'multiple-select',
      wordings: {
        title: t(`${targetZones}.${title}`)
      },
      options: [
        {
          id: legsAndGlutes,
          label: t(`${targetZones}.${options}.${legsAndGlutes}`),
          emoji: '🦵'
        },
        {
          id: chest,
          label: t(`${targetZones}.${options}.${chest}`),
          emoji: '🤽‍♂️'
        },
        {
          id: armsAndShoulders,
          label: t(`${targetZones}.${options}.${armsAndShoulders}`),
          emoji: '🏋️‍♂️'
        },
        {
          id: abs,
          label: t(`${targetZones}.${options}.${abs}`),
          emoji: '🏊'
        },
        {
          id: fullBody,
          label: t(`${targetZones}.${options}.${fullBody}`),
          emoji: '🤸‍♂️'
        }
      ]
    }
  } else {
    return {
      id: targetZones,
      type: 'multiple-select',
      wordings: {
        title: t(`${targetZones}.${title}`)
      },
      options: [
        {
          id: legs,
          label: t(`${targetZones}.${options}.${legs}`),
          emoji: '🦵'
        },
        {
          id: chest,
          label: t(`${targetZones}.${options}.${chest}`),
          emoji: '🤽‍♀️'
        },
        {
          id: glutes,
          label: t(`${targetZones}.${options}.${glutes}`),
          emoji: '🍑'
        },
        {
          id: belly,
          label: t(`${targetZones}.${options}.${belly}`),
          emoji: '👙'
        },
        {
          id: fullBody,
          label: t(`${targetZones}.${options}.${fullBody}`),
          emoji: '🤸‍♂️'
        }
      ]
    }
  }
}
