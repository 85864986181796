import CreateAccountModal from 'Components/Login/CreateAccountModal'
import LoginWithEmailAndPassword from 'Components/Login/LoginWithEmailAndPassword'
import SocialLogin from 'Components/Login/SocialLogin'
import Logo from 'Components/Logo'
import OrLineDivider from 'Components/OrLineDivider'
import { checkIfIsEmbeddedBrowser } from 'Lib/UIUtils'
import { AnalyticEvents } from 'Lib/Constants'
import { ActionType } from 'Reducers'
import { useEffect, useState } from 'react'
// import { useGoogleOneTapLogin } from 'react-google-one-tap-login'
import { FirebaseError } from '@firebase/util'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'store'
import styled from 'styled-components'
import { showToast } from 'Components/Toast'

const Center = styled.div`
  display: flex;
  justify-content: center;
`
const MaxWidth = styled.div`
  max-width: 400px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.small};
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.zero};
`

const SocialLoginContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.large};
`

// const CLIENT_ID = getGoogleClientID(getEnvironment())

interface LoginContainerProps {
  email?: string
  onLoginSuccess?: () => void
}

const LoginContainer: React.FC<LoginContainerProps> = ({ email, onLoginSuccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showUserNotFoundModal, setShowUserNotFoundModal] = useState(false)
  const [loginAndPassword, setLoginAndPassword] = useState({ email: email || '', password: '' })

  const isEmbeddedBrowser = checkIfIsEmbeddedBrowser()

  const showErrorMessage = (error: FirebaseError) => {
    if (error.code === 'auth/user-not-found') {
      setShowUserNotFoundModal(true)
      return
    }

    const errorMessage = t(`FirebaseError.${error.code}`, { ns: 'translation', defaultValue: t('FirebaseError.auth/unknown', { ns: 'translation' }) })
    showToast('error', errorMessage)
  }

  const handleSuccess = () => {
    if (onLoginSuccess) {
      onLoginSuccess()
    } else {
      dispatch({ type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE })
    }
  }

  const handleLoginWithEmailAndPassword = (formData: {
    email: string
    password: string
  }) => {
    dispatch({
      type: ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
      ...formData,
      onError: showErrorMessage,
      onSuccess: handleSuccess
    })
    setLoginAndPassword(formData)
  }

  const handleCreateAccount = async () => {
    dispatch({
      type: ActionType.SIGN_UP_WITH_EMAIL_REQUEST,
      ...loginAndPassword,
      onError: showErrorMessage,
      onSuccess: handleSuccess
    })
  }

  useEffect(() => {
    if (!showUserNotFoundModal) return

    logEvent(
      showUserNotFoundModal
        ? AnalyticEvents.WEB_OPEN_USER_NOT_FOUND_MODAL
        : AnalyticEvents.WEB_CLOSE_USER_NOT_FOUND_MODAL
    )
  }, [showUserNotFoundModal])

  const handleGoogleLoginPress = () => {
    dispatch({
      type: ActionType.SIGN_IN_WITH_GOOGLE_REQUEST,
      onSuccess: handleSuccess,
      onError: showErrorMessage
    })
  }

  const handleAppleLoginPress = () => {
    dispatch({
      type: ActionType.SIGN_IN_WITH_APPLE_REQUEST,
      onSuccess: handleSuccess,
      onError: showErrorMessage
    })
  }

  // Disabled for now (see https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1694784074638829?thread_ts=1694771714.036249&cid=C04J5V1PN8G)
  // const handleOneTapLoginSuccess = (response: any) => {
  //   const { credential } = response

  //   dispatch({
  //     type: ActionType.SIGN_IN_WITH_GOOGLE_ONE_TAP,
  //     idToken: credential,
  //     onError: showErrorMessage,
  //     onSuccess: handleSuccess
  //   })
  // }

  // useGoogleOneTapLogin({
  //   onError: showErrorMessage,
  //   onSuccess: handleOneTapLoginSuccess,
  //   disabled: isEmbeddedBrowser,
  //   googleAccountConfigs: {
  //     client_id: CLIENT_ID,
  //     cancel_on_tap_outside: true,
  //     callback: handleOneTapLoginSuccess
  //   }
  // })

  return (
    <Center>
      <MaxWidth>
        <Logo />
        <ContentWrapper>
          <Title>
            {t('loginScreen.title')}
          </Title>
          <p>{t('loginScreen.description')}</p>
          <LoginWithEmailAndPassword
            handleLoginWithEmailAndPassword={handleLoginWithEmailAndPassword}
            email={email}
          />
          {!isEmbeddedBrowser &&
            <>
              <OrLineDivider />
              <SocialLoginContainer>
                <SocialLogin
                  onGooglePress={handleGoogleLoginPress}
                  onApplePress={handleAppleLoginPress}
                />
              </SocialLoginContainer>
            </>
          }
          {showUserNotFoundModal &&
            <CreateAccountModal
              closeModal={() => setShowUserNotFoundModal(false)}
              createAccount={handleCreateAccount}
            />
          }
        </ContentWrapper>
      </MaxWidth>
    </Center>
  )
}

export default LoginContainer
