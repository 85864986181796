import React from 'react'
import styled, { StyledComponent } from 'styled-components'
import { Step } from '../types/onboardingTypes'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'

interface InfoStepProps {
  stepConfig: Step
}

const InfoStep: React.FC<InfoStepProps> = ({ stepConfig }) => {
  return (
    <div>
      <StepTitle>{stepConfig.wordings.title}</StepTitle>
      <StepSubtitle>{stepConfig.wordings.subtitle}</StepSubtitle>
    </div>
  )
}

export default InfoStep
