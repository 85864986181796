import { formatTimeLeft } from 'Lib'
import { Selectors } from 'Reducers'
import invariant from 'invariant'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineClock } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { PaymentColorPackage } from 'types/onboardingTypes'

const DiscountGiftContainer = styled.div<{ color: string }>`
  width: 100%;
  max-width: 404px;
  align-items: flex-start;
  margin: 0px ${({ theme }) => theme.spacing.xLarge};
  background-color: ${({ color }) => color}99;
  border-radius: ${({ theme }) => theme.spacing.small};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const GiftIcon = styled.span`
  font-size: 48px;
  margin-right: ${({ theme }) => theme.spacing.small};
  margin-left: ${({ theme }) => theme.spacing.medium};
`
const GiftTextsContainer = styled.div<{ textColor: string}>`
  padding: ${({ theme }) => theme.spacing.medium};
  padding-left: ${({ theme }) => theme.spacing.medium};
  font-weight: bold;
  color: ${({ textColor }) => textColor};
`
const GiftWithTimerTextsContainer = styled.div<{ textColor: string}>`
padding: ${({ theme }) => theme.spacing.medium};
padding-left: 0;
font-weight: bold;
color: ${({ textColor }) => textColor};
`

const DiscountGiftPercentage = styled.strong`
  color: #ff4f64;
  margin-right: 4px;
  margin-left: 4px;
  display: contents;
  font-size: 1.2em;
`

const GiftTimeLeftContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-weight: normal;
`

const GiftTimeLeft = styled.strong`
  align-self: flex-start;
`

const DiscountCountdownContainer = styled.div<{ color: string }>`
  width: 100%;
  max-width: 404px;
  margin: 0px ${({ theme }) => theme.spacing.xLarge};
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.spacing.small};
`

const DiscountCountdownText = styled.strong`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
`

const Icon = styled(HiOutlineClock)`
  width: 22px;
  height: 22px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: 1px;
`

interface DiscountBannerProps {
  colorPackage: PaymentColorPackage
  discountPercentage: number
}

enum BannerStyleType {
  GIFT_AND_COUNTDOWN = 'gift_and_countdown',
  COUNTDOWN = 'countdown',
  GIFT = 'gift'
}

const DiscountBanner = ({ colorPackage, discountPercentage }: DiscountBannerProps) => {
  const { t } = useTranslation()
  const offerDiscount = useSelector(Selectors.getOfferDiscount)
  const discountBannerType = useSelector(Selectors.getDiscountBannerType)
  const discountTimeRemaining = useSelector(Selectors.getDiscountTimeRemaining)

  const formattedTimeRemaining = formatTimeLeft(discountTimeRemaining)

  const getBannerStyleTypeToDisplay = (): BannerStyleType => {
    if (discountBannerType === 'gift' && offerDiscount) {
      invariant(discountTimeRemaining > 0, 'discountTimeLeft is required when discountBannerType is gift_and_countdown')
      return BannerStyleType.GIFT_AND_COUNTDOWN
    }

    if (offerDiscount) {
      return BannerStyleType.COUNTDOWN
    }

    return BannerStyleType.GIFT
  }

  switch (getBannerStyleTypeToDisplay()) {
    case BannerStyleType.GIFT_AND_COUNTDOWN:
      return (
        <DiscountGiftContainer color={colorPackage.bannerColor}>
          <GiftIcon>🎁</GiftIcon>
          <GiftWithTimerTextsContainer textColor={colorPackage.bannerTextColor} >
            <div>
              <Trans
                values={{ discountPercentage: `${discountPercentage}%` }}
                i18nKey='package.discountGift'
                components={[<DiscountGiftPercentage />]}
              />
            </div>
            <GiftTimeLeftContainer>
              <Trans
                values={{ timeString: formattedTimeRemaining }}
                i18nKey='package.discountGiftTimeout'
                components={[<GiftTimeLeft />]}
              />
            </GiftTimeLeftContainer>
          </GiftWithTimerTextsContainer>
        </DiscountGiftContainer>
      )

    case BannerStyleType.COUNTDOWN:
      return (
        <DiscountCountdownContainer color={colorPackage.bannerColor}>
          <DiscountCountdownText><Icon />{t('package.discountTimeout', { timeString: formattedTimeRemaining })}</DiscountCountdownText>
        </DiscountCountdownContainer>
      )

    case BannerStyleType.GIFT:
      return (
        <DiscountGiftContainer color={colorPackage.bannerColor}>
          <GiftIcon>🎁</GiftIcon>
          <GiftTextsContainer textColor={colorPackage.bannerTextColor} >
            <div>
              <Trans
                values={{ discountPercentage: `${discountPercentage}%` }}
                i18nKey='package.discountGift'
                components={[<DiscountGiftPercentage />]}
              />
            </div>
          </GiftTextsContainer>
        </DiscountGiftContainer>
      )
  }
}

export default DiscountBanner
