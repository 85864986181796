import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'

export function getBMI(t: TFunction): Step {
  return {
    id: 'bmi',
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t('')
    }
  }
}
