import {
  ActionType,
  Selectors
} from 'Reducers'
import { IAuthStateChangedAction } from 'Reducers/LoginRedux'
import { waitForStartup } from 'Sagas/HelperSagas'
import { delay, race } from 'redux-saga/effects'
import { all, call, put, select, take, takeLatest } from 'typed-redux-saga'

function * handleAuhStateChangeSaga({ authData }: IAuthStateChangedAction) {
  if (authData) {
    yield call(finalizeLoginSaga)
  } else {
    yield call(finalizeLogoutSaga)
  }
}

export function * finalizeLoginSaga () {
  // new firebase user ref becomes active and sets user
  // const action : UpdateUserSuccessAction = yield take(LoginTypes.UPDATE_USER_SUCCESS)
  // const { newUserCreated } = action

  yield * call(waitForStartup)

  // if (newUserCreated) {
  //   // yield * put(SettingsActions.updateSetting('onBoardingInProgress', true))
  //   yield * put(OnboardingActions.startOnboarding())
  //   yield * put(LoginActions.updateUserChangesetRequest({ onboardingActive: true }))
  // } else if ((yield * select(Selectors.isOnboardingInProgress))) {
  //   // If a user is using 2 devices, ActiveOnboardingStep can be null when user.onboardingActive is true for that user.
  //   // If this happens, we should direct that users to the intro screen.
  //   const activeOnboardingStep = (yield * select(Selectors.getActiveOnboardingStep)) ?? 'intro'
  //   yield * put(OnboardingActions.goToOnboardingStep(nullthrows(activeOnboardingStep)))
  // } else if (yield * select(Selectors.shouldShowPostPurchaseScreen)) {
  //   yield * put(NavigationActions.navigate({ routeName: RouteNames.Main }))
  //   yield * put(NavigationActions.navigate({ routeName: RouteNames.ThankYouScreen }))
  // } else {
  //   yield * put(NavigationActions.navigate({ routeName: RouteNames.Main }))
  // }

  yield put({ type: ActionType.LOGIN_SUCCESS })
}

export function * finalizeLogoutSaga () {
  yield * call(waitForStartup)
  yield put({ type: ActionType.LOGOUT_SUCCESS })

  // yield * put(NavigationActions.navigate({ routeName: RouteNames.welcomeMain }))
}

export function * LoginSagas() {
  yield all([
    takeLatest(ActionType.AUTH_STATE_CHANGED, handleAuhStateChangeSaga),
  ])
}
