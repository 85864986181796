import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getSleepQuality(t: TFunction): Step {
  const sleepQuality = 'sleep_quality'

  // "less_than_5": "Less than 5 hours of sleep",
  // "between_5_and_6": "5-6 hours of sleep",
  // "between_7_and_8": "7-8 hours of sleep",
  // "more_than_8": "More than 8 hours of sleep"

  const lessThan5 = 'less_than_5'
  const between5and6 = 'between_5_and_6'
  const between7and8 = 'between_7_and_8'
  const moteThan8 = 'more_than_8'

  return {
    id: sleepQuality,
    type: 'single-select',
    wordings: {
      title: t(`${sleepQuality}.${title}`)
    },
    options: [
      {
        id: lessThan5,
        label: t(`${sleepQuality}.${options}.${lessThan5}`),
        emoji: '🥱'
      },
      {
        id: between5and6,
        label: t(`${sleepQuality}.${options}.${between5and6}`),
        emoji: '💤'
      },
      {
        id: between7and8,
        label: t(`${sleepQuality}.${options}.${between7and8}`),
        emoji: '😴'
      },
      {
        id: moteThan8,
        label: t(`${sleepQuality}.${options}.${moteThan8}`),
        emoji: '🛏️'
      }
    ]
  }
}
