import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'

export function getReinforcementBeforeAfter(t: TFunction, gender?: IGender): Step {
  const femaleTitle = 'reinforcement-before-after.titleFemale'
  const maleTitle = 'reinforcement-before-after.titleMale'

  const title = gender === 'f' ? femaleTitle : maleTitle

  return {
    id: 'reinforcement_before_after',
    type: 'information',
    allowNext: true,
    wordings: {
      title: t(title)
    }
  }
}
