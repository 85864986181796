import { AnalyticEvents } from 'Lib/Constants'
export interface IAppsFlyerService {
  grantConsent: () => void
  revokeConsent: () => void
}

export const createAppsFlyerService = (): IAppsFlyerService | null => {
  if (__DEV__) {
    return null
  }
  const appsFlyerService = {
    // Documentation explaining controlling AppsFlyer: https://support.appsflyer.com/hc/en-us/articles/360001610038-PBA-Web-SDK-integration-guide#explicit-control

    // setInterval() methods below are implemented as a safety measure:
    // There might be cases where grantConsent() method is called before the script is initialized or before window.AF_SDK.PLUGINS.PBA.enableMeasurement() is made available.
    // i.e: Whem appsFlyerService.grantConsent() is called in initCookieService() in CookieServices.ts
    // In such cases, code will keep trying to call the method until it is available.
    // and log unsuccessful attempts to the analytics.

    grantConsent: () => {
      let tryCount = 0
      const interval = setInterval(() => {
        if (window.AF_SDK?.PLUGINS?.PBA?.enableMeasurement) {
          window.AF_SDK?.PLUGINS?.PBA?.enableMeasurement?.()
          clearInterval(interval)
        } else {
          tryCount++
          if (tryCount === 10) {
            logEvent(AnalyticEvents.WEB_APPSFLYER_SERVICE_NOT_INITIALIZED, { tryCount: tryCount, functionName: 'grantConsent' })
            clearInterval(interval)
          }
        }
      }, 100)
    },
    revokeConsent: () => {
      let tryCount = 0
      const interval = setInterval(() => {
        if (window.AF_SDK?.PLUGINS?.PBA?.disableMeasurement) {
          window.AF_SDK?.PLUGINS?.PBA?.disableMeasurement?.()
          clearInterval(interval)
        } else {
          tryCount++
          if (tryCount % 10 === 0) {
            logEvent(AnalyticEvents.WEB_APPSFLYER_SERVICE_NOT_INITIALIZED, { tryCount: tryCount, functionName: 'revokeConsent' })
            clearInterval(interval)
          }
        }
      }, 100)
    }
  }
  return appsFlyerService
}
