import { IRootState } from './RootRedux'

export const getDiscountTimeRemaining = ({
  discount: { discountExpiresAt, pauseTimerOffset },
  timer: { now }
}: IRootState): number => {
  if (!discountExpiresAt || !now) {
    return -1
  }

  const diffSeconds = discountExpiresAt + (pauseTimerOffset ?? 0) - now
  if (diffSeconds < 0) {
    return -1
  }

  return diffSeconds
}

export const getOfferDiscount = ({
  discount: { offerDiscount, discountExpiresAt, pauseTimerOffset },
  timer: { now }
}: IRootState): boolean => {
  if (!offerDiscount || !now) {
    return false
  }

  if (!discountExpiresAt) {
    return true
  }

  return (discountExpiresAt + (pauseTimerOffset ?? 0) - now) > 0
}

export const Selectors = {
  getUser: (state: IRootState) => state.login.user,
  getEmail: (state: IRootState): string | null => state.login.user?.providerData.email ?? state.login.user?.newsletterEmail ?? null,
  // We have to check whether the user is registered or not by checking if he has an email
  // because the user can be logged in with a token, and in that case, even if he doesn't have
  // an account (is anonymous), the property [anonymous] will be false
  isRegisteredUser: (state: IRootState) => !!state.login.user?.providerData?.providerId,
  isLoggedIn: (state: IRootState) => !!state.login.isLoggedIn,
  getSubscription: (state: IRootState) => state.login.user?.subscription,
  getGender: (state: IRootState) => state.login.user?.personal?.gender,
  isAppStarted: (state: IRootState) => state.startup.isAppStarted,
  isFirebaseInitialized: (state: IRootState) => state.startup.isFirebaseInitialized,
  isSentryInitialized: (state: IRootState) => state.startup.isSentryInitialized,
  isAuthInitialized: (state: IRootState) => state.startup.isAuthInitialized,
  isStripeInitialized: (state: IRootState) => state.startup.isStripeInitialized,
  getAppBlocked: (state: IRootState) => state.App.appBlocked,
  getDiscountTimeRemaining: (state: IRootState) => getDiscountTimeRemaining(state),
  getOfferDiscount: (state: IRootState) => getOfferDiscount(state),
  getPauseTimerOffset: (state: IRootState) => state.discount.pauseTimerOffset,
  getDiscountBannerType: (state: IRootState) => state.discount.discountBannerType,
  getNow: (state: IRootState) => state.timer.now
}
