import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getFastingKnowledge(t: TFunction): Step {
  const fastingKnowledge = 'fasting_knowledge'

  const nothing = 'nothing'
  const aBit = 'a_bit'
  const expert = 'expert'

  return {
    id: fastingKnowledge,
    type: 'single-select',
    wordings: {
      title: t(`${fastingKnowledge}.${title}`)
    },
    options: [
      {
        id: nothing,
        label: t(`${fastingKnowledge}.${options}.${nothing}`),
        emoji: '🙋'
      },
      {
        id: aBit,
        label: t(`${fastingKnowledge}.${options}.${aBit}`),
        emoji: '📚'
      },
      {
        id: expert,
        label: t(`${fastingKnowledge}.${options}.${expert}`),
        emoji: '💡'
      }
    ]
  }
}
