import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { title } from './Consts'

export function getReceiveEmail(t: TFunction): Step {
  return {
    id: 'receive_email',
    type: 'custom',
    wordings: {
      title: t(`receive-email.${title}`)
    }
  }
}
