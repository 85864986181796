import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'

export function getGenderSelection(t: TFunction): Step {
  return {
    id: 'gender',
    type: 'custom',
    wordings: {
      title: ''
    }
  }
}
