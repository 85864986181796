import React, { useState } from 'react'
import styled from 'styled-components'
import { Step } from '../types/onboardingTypes'
import OptionWrapper from './OptionWrapper'
import StepItemImage from './StepItemImage'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'
import { theme } from 'styles/theme'
import SkipComponent from './SkipComponent'

interface MultiSelectStepProps {
  stepConfig: Step
  onSelectionChange: (selectedOptionIds: string[] | undefined) => void
  onSkip: () => void
  initialValue?: string[]
}

const Emoji = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.large};
`

const OptionsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const OptionLabel = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const CheckboxIcon = styled.svg<{ selected: boolean }>`
  fill: ${({ selected }) => (selected ? theme.colors.white : theme.colors.transparent)};
  stroke: ${({ selected, theme }) => (selected ? theme.colors.primary : '#999')};
  stroke-width: 2px;
  margin-right: ${({ theme }) => theme.spacing.small};
`

const MultiSelectStep: React.FC<MultiSelectStepProps> = ({ stepConfig, onSelectionChange, onSkip, initialValue }) => {
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set(initialValue))

  const handleOptionClick = (optionId: string) => {
    const newSelectedOptions = new Set(selectedOptions)
    if (newSelectedOptions.has(optionId)) {
      newSelectedOptions.delete(optionId)
    } else {
      newSelectedOptions.add(optionId)
    }
    setSelectedOptions(newSelectedOptions)
    if (newSelectedOptions.size === 0) {
      onSelectionChange(undefined)
    } else {
      onSelectionChange(Array.from(newSelectedOptions))
    }
  }

  return (
    <div>
      <StepTitle>{stepConfig.wordings.title}</StepTitle>
      <StepSubtitle>{stepConfig.wordings.subtitle}</StepSubtitle>
      <OptionsSection>
        {stepConfig.options?.map((option) => {
          const isSelected = selectedOptions.has(option.id)
          return (
            <OptionWrapper
              key={option.id}
              onClick={() => handleOptionClick(option.id)}
              selected={isSelected}
            >
              <CheckboxIcon
                width="24"
                height="24"
                viewBox="0 0 24 24"
                selected={isSelected}
              >
                <rect x="5" y="5" width="14" height="14" />
                {isSelected && <path d="M9 12l2 2l4 -4" />}
              </CheckboxIcon>
              <OptionLabel>
                <span>{option.label}</span>
              </OptionLabel>
              {(option.maleImage ?? option.femaleImage) && <StepItemImage src={option.maleImage ?? option.femaleImage} alt={option.label} />}
              {(option.emoji && !(option.maleImage ?? option.femaleImage)) && <Emoji>{option.emoji}</Emoji>}
            </OptionWrapper>
          )
        })}
        {stepConfig.allowSkip &&
          <SkipComponent onClick={onSkip} />
        }
      </OptionsSection>
    </div>
  )
}

export default MultiSelectStep
