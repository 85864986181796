import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getAgeRange(t: TFunction, gender?: IGender): Step {
  const ageRange = 'age_range'
  const age18to29 = 'age_18_to_29'
  const age30to39 = 'age_30_to_39'
  const age40to49 = 'age_40_to_49'
  const age50andUp = 'age_50_and_up'

  return {
    id: ageRange,
    type: 'single-select',
    wordings: {
      title: t(`${ageRange}.${title}`)
    },
    /*
    Chrome for mobile treats texts like '18 - 29' as clickable numbers and underlines them.
    To overcome this hurdle, we add a zero-width space character between each character of the text.
    To keep accessibility, we add the original text as an aria-label to not break screen readers.
    */
    options: [
      {
        id: age18to29,
        label: t(`${ageRange}.${options}.${age18to29}`).split('').join('\u200B'),
        ariaLabel: t(`${ageRange}.${options}.${age18to29}`) || undefined
      },
      {
        id: age30to39,
        label: t(`${ageRange}.${options}.${age30to39}`).split('').join('\u200B'),
        ariaLabel: t(`${ageRange}.${options}.${age30to39}`) || undefined
      },
      {
        id: age40to49,
        label: t(`${ageRange}.${options}.${age40to49}`).split('').join('\u200B'),
        ariaLabel: t(`${ageRange}.${options}.${age40to49}`) || undefined
      },
      {
        id: age50andUp,
        label: t(`${ageRange}.${options}.${age50andUp}`).split('').join('\u200B')
      },
    ]
  }
}
