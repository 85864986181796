import { BodyFastAction } from 'Reducers'
import { ActionType } from './ActionTypes'
import { DateTime } from 'luxon'

export interface TimerState {
  running: boolean
  now: number | null
}

export interface ITimerTick { type: ActionType.TIMER_TICK, elapsedSeconds: number }
export interface ITimerStop { type: ActionType.TIMER_STOP }

export type TimerAction = ITimerTick | ITimerStop

export const INITIAL_STATE: TimerState = {
  running: false,
  now: DateTime.now().toUnixInteger()
}

export const TimerReducer = (
  state: TimerState = INITIAL_STATE,
  action: BodyFastAction
): TimerState => {
  switch (action.type) {
    case ActionType.TIMER_TICK: {
      return {
        ...state,
        now: DateTime.now().toUnixInteger(),
        running: true,
      }
    }
    case ActionType.TIMER_STOP: {
      return {
        ...state,
        running: false,
      }
    }
    default:
      return state
  }
}
