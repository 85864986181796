import { GrowthBookProvider } from '@growthbook/growthbook-react'
import * as Sentry from '@sentry/react'
import { AppStateProvider } from 'AppContextProvider'
import { firebaseServicePromise, store } from 'store'
import ScrollToTop from 'Containers/ScrollToTop.container'
import { history } from 'Reducers'
import RootRouter from 'RootRouter'
import { getGrowthbookInstance } from 'Services/GrowthBook.service'
import { ConnectedRouter } from 'connected-react-router'
import 'i18n'
import { images } from 'images'
import { Suspense, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/globalStyle'
import { theme } from 'styles/theme'
import DevelopmentPanel from 'Containers/DevelopmentPanel.container'
import URLParamSync from 'Components/URLParamSync'
import { ToastProvider } from 'Components/Toast'

const growthbook = getGrowthbookInstance()

const App: React.FC = () => {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)

  // Simple way to delay rendering of mounted routes until analytics has been loaded
  // If at some point this is not enough (need to init several dependent services)
  // Then follow the same mechanism as in mobile, move it to sagas and create a web of dependencies&events
  useEffect(() => {
    void firebaseServicePromise.then(() => setFirebaseInitialized(true))

    // Load features asynchronously when the app renders
    void growthbook.loadFeatures()
  }, [])

  if (!firebaseInitialized) {
    return null
  }

  const preloadImageList = () => {
    return (
      <>
        <link rel="preload" href={images.SelectedLeftRoundedArrow} as="image" />
        <link rel="preload" href={images.LeftRoundedArrow} as="image" />
      </>
    )
  }

  return (
    <Sentry.ErrorBoundary fallback={<p></p>}>
      <GrowthBookProvider growthbook={growthbook}>
        <BrowserRouter>
          {preloadImageList()}
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Provider store={store}>
              {/* i18next fetches the translations async this halt¡s the app until the translations are downloaded */}
              <ConnectedRouter history={history}>
                <ScrollToTop />
                <Suspense fallback={<></>}>
                  <AppStateProvider>
                    <RootRouter />
                    {__DEV__ && <DevelopmentPanel />}
                    <URLParamSync />
                    <ToastProvider/>
                  </AppStateProvider>
                </Suspense>
              </ConnectedRouter>
            </Provider>
          </ThemeProvider>
        </BrowserRouter>
      </GrowthBookProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
