import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'

export function getTrackedHours(t: TFunction): Step {
  return {
    id: 'tracked_hours',
    type: 'custom',
    allowNext: true,
    wordings: {
      title: ''
    }
  }
}
