import { AnalyticEvents } from 'Lib/Constants'
import { useLocation } from 'react-router-dom'
import { BadgePackageTagId, PackageOrderStyle, PaymentColorScheme, PromotionLabelStyle } from 'types/onboardingTypes'
import { useFeatureValue } from '@growthbook/growthbook-react'

/**
 * uses browser URLSearchParams API (not supported in IE11) to parse url query
 * */
export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

// GrowthBook feature flags
export function usePaymentScreenColorScheme(): PaymentColorScheme {
  const colorScheme = useFeatureValue('payment-screen-color-palette', 'original')

  if (['blue', 'classic', 'green', 'orange', 'original'].includes(colorScheme)) {
    return colorScheme as PaymentColorScheme
  } else {
    logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected color scheme'), { colorScheme }, true)
    return 'original'
  }
}

export function usePaymentPromotionLabelStyle(): PromotionLabelStyle {
  const promotionLabelStyle = useFeatureValue('payment-screen-promotion-label-style', 'original')

  if (['original', 'mostPopularFastTrack'].includes(promotionLabelStyle)) {
    return promotionLabelStyle as PromotionLabelStyle
  } else {
    logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected promotionLabelStyle'), { promotionLabelStyle }, true)
    return 'original'
  }
}

export function useStripePackageOrder(): PackageOrderStyle {
  const fallbackValue: PackageOrderStyle = 'original' as PackageOrderStyle
  const packageOrderStyle = useFeatureValue('payment-screen-package-order-style', fallbackValue)

  if (['original', 'reversed'].includes(packageOrderStyle)) {
    return packageOrderStyle as PackageOrderStyle
  }
  logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected package order style'), { packageOrderStyle }, true)
  return fallbackValue
}

export function useBadgePackageTagId(): BadgePackageTagId {
  const badgePackageTagId = useFeatureValue('payment-screen-badge-package-tag-id', BadgePackageTagId.None) as BadgePackageTagId

  if (!Object.values(BadgePackageTagId).includes(badgePackageTagId)) {
    logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected badgePackageTagId'), { badgePackageTagId }, true)
    return BadgePackageTagId.None
  }
  return badgePackageTagId
}
