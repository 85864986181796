import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getLunchTime(t: TFunction): Step {
  const lunchTime = 'lunch_time'
  const before1pm = 'before1pm'
  const between1and2 = 'between1and2'
  const between2and4 = 'between2and4'
  const skip = 'skip'

  return {
    id: lunchTime,
    type: 'single-select',
    wordings: {
      title: t(`${lunchTime}.${title}`)
    },
    options: [
      {
        id: before1pm,
        label: t(`${lunchTime}.${options}.${before1pm}`),
        emoji: '🕛'
      },
      {
        id: between1and2,
        label: t(`${lunchTime}.${options}.${between1and2}`),
        emoji: '🕑'
      },
      {
        id: between2and4,
        label: t(`${lunchTime}.${options}.${between2and4}`),
        emoji: '🕓'
      },
      {
        id: skip,
        label: t(`${lunchTime}.${options}.${skip}`),
        emoji: '❌'
      }
    ]
  }
}
