import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getGoal(t: TFunction, gender?: IGender): Step {
  const goals = 'goals'
  const loseWeight = 'lose_weight'
  const improveSleep = 'improve_sleep'
  const improveOverallHealth = 'improve_overall_health'
  const boostEnergy = 'boost_energy'
  const strengthenCognitiveFunctions = 'strengthen_cognitive_functions'
  const supportLongevity = 'support_longevity'

  return {
    id: goals,
    type: 'multiple-select',
    wordings: {
      title: t(`${goals}.${title}`)
    },
    options: [
      {
        id: loseWeight,
        label: t(`${goals}.${options}.${loseWeight}`),
        emoji: gender === 'm' ? '🩳' : '👙'
      },
      {
        id: improveOverallHealth,
        label: t(`${goals}.${options}.${improveOverallHealth}`),
        emoji: '🩺'
      },
      {
        id: boostEnergy,
        label: t(`${goals}.${options}.${boostEnergy}`),
        emoji: '🚀'
      },
      {
        id: strengthenCognitiveFunctions,
        label: t(`${goals}.${options}.${strengthenCognitiveFunctions}`),
        emoji: '🧠'
      },
      {
        id: improveSleep,
        label: t(`${goals}.${options}.${improveSleep}`),
        emoji: '😴'
      },
      {
        id: supportLongevity,
        label: t(`${goals}.${options}.${supportLongevity}`),
        emoji: gender === 'm' ? '👴' : '👵'
      }
    ]
  }
}
