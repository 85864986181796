import { useStripe } from '@stripe/react-stripe-js'
import { useAppState } from 'AppContextProvider'
import { ActionType, Selectors } from 'Reducers'
import { images } from 'images'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import styled from 'styled-components'
import { CustomPackage } from 'types/onboardingTypes'

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ApplePayButtonComponent = styled.button`
  background-size: cover;
  border-radius: ${props => props.theme.spacing.xsmall};
  border: 1px solid black;
  background-color: black;
  color: white;
  height: 50px;
  width: 100%;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.medium};

  img {
    width: ${props => props.theme.spacing.medium};
    height: ${props => props.theme.spacing.medium};
  }

  p {
    margin: 0;
  }
`

const ApplePayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: ${props => props.theme.spacing.xsmall};
  flex: 1;
`

interface ApplePayButtonProps {
  onError: (err: any) => void
  selectedPackage: CustomPackage
}

const ApplePayButton: React.FC<ApplePayButtonProps> = ({ selectedPackage, onError }) => {
  const isAppBlocked = useSelector(Selectors.getAppBlocked)
  const stripe = useStripe()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { appState } = useAppState()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    dispatch({
      type: ActionType.CREATE_STRIPE_SESSION_REQUEST,
      packages: appState.stripePrices,
      selectedPackage,
      onSuccess: (sessionId: string) => {
        void stripe?.redirectToCheckout({ sessionId: sessionId })
      },
      onError: (error: string) => {
        onError(error)
      }
    })
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <ApplePayButtonComponent disabled={isAppBlocked} type="submit">
        {isAppBlocked ? <BeatLoader size={8} color="#ffffff" />
          : <ApplePayButtonWrapper>
            <img src={images.appleLogo} />
            <p>{t('modal.applePayButton')}</p>
          </ApplePayButtonWrapper>
        }
      </ApplePayButtonComponent>
    </FormWrapper>
  )
}

export default ApplePayButton
