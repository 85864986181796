import * as Sentry from '@sentry/react'
import { formatStripePackage, getSearchParams, usePaymentPromotionLabelStyle, useStripePackageOrder } from 'Lib'
import { RouteNames } from 'RouteNames'
import { DefaultConfig, AnalyticEvents } from 'Lib/Constants'
import { setGrowthbookAttributes } from 'Services/GrowthBook.service'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { firebaseServicePromise } from 'store'
import { CustomPackage, ONBOARDING_STEPS, Selections, Step } from 'types/onboardingTypes'
import { StripePricesType, fetchStripePrices } from 'Services'
import { useSelector } from 'react-redux'
import { Selectors } from 'Reducers'
import { showToast } from 'Components/Toast'

export interface AppContextState {
  selections?: Selections
  stepsIdsToShowInProgressBar?: ONBOARDING_STEPS[]
  stepsToShowIds?: ONBOARDING_STEPS[]
  steps?: Step[]
  currentStep?: Step
  prevStep?: Step
  stripePrices?: CustomPackage[]
  paymentSuccess?: boolean
  paypalCheckoutEnabled?: boolean
  paypalElementsEnabled?: boolean
  paypalNativeFallbackEnabled?: boolean
  isPurchaseAvailable?: boolean
}

const useAppState = () => {
  const context = useContext(AppStateContext)
  if (!context) {
    throw new Error('useAppState must be used within an AppStateProvider')
  }
  return context
}

const AppStateContext = createContext<{
  appState: AppContextState
  setAppState: React.Dispatch<React.SetStateAction<AppContextState>>
  initStorage: () => void
  clearAppState: () => void
}>({ appState: {}, setAppState: () => { }, initStorage: () => { }, clearAppState: () => { } })

const AppStateProvider: React.FC = ({ children }) => {
  const [appState, setAppState] = useState<AppContextState>({})
  const shouldLoadPackages = useRef(true)
  const { t } = useTranslation()
  const history = useHistory()
  const offerDiscount = useSelector(Selectors.getOfferDiscount)

  const stripePackageOrder = useStripePackageOrder()
  const promotionLabelStyle = usePaymentPromotionLabelStyle()

  const searchParams = getSearchParams()
  const searchParamsSubscriptionGroup = searchParams.get('sgroup')
  const promoGroup = searchParamsSubscriptionGroup ?? localStorage.getItem('sgroup') ?? DefaultConfig.SUBSCRIPTION_GROUP

  let shouldCleanUrl = false
  if (searchParamsSubscriptionGroup) {
    localStorage.setItem('sgroup', searchParamsSubscriptionGroup)
    searchParams.delete('sgroup')
    shouldCleanUrl = true
  }

  /*
  Once we get the stripe prices we format them. Some parameters are coming from growthbook so we need to fetch them as well.
  When we try formatting in the initStorage, we are not able to get the stripePackageOrder, useFastTrack, useMostPopular features.
  So we need to format prices once again after receiving the growtbhook features.
  We also need the data from 'await fetchStripePrices' to be able to call 'formatStripePackage' so we introduced stripePrices below as well.
  */
  const [stripePrices, setStripePrices] = useState<StripePricesType | null>(null)
  useEffect(() => {
    if (!stripePrices) {
      return
    }

    const packages = formatStripePackage(stripePrices, promotionLabelStyle, t, offerDiscount, stripePackageOrder)
    setAppState((prevState) => ({
      ...prevState,
      stripePrices: packages
    }))
  }, [stripePrices, stripePackageOrder, promotionLabelStyle, !offerDiscount])

  const initStorage = async () => {
    if (appState.stripePrices ?? !shouldLoadPackages.current) {
      return
    }

    shouldLoadPackages.current = false

    const currency = getCurrency()

    try {
      const { data } = await fetchStripePrices(promoGroup, currency)

      if (data) {
        if (data.countryCode) {
          localStorage.setItem('countryCode', data.countryCode)

          setGrowthbookAttributes({
            country: data.countryCode
          })
        }
        setStripePrices(data)
        const packages = formatStripePackage(data, promotionLabelStyle, t, offerDiscount, stripePackageOrder)
        setAppState((prevState) => ({
          ...prevState,
          stripePrices: packages,
          // some currencies are supported by stripe but not paypal.
          // hide the paypal button in this case, because it will fallback to USD and the prices will be inconsistent between payment methods
          paypalCheckoutEnabled: data.paypalCheckoutEnabled,
          paypalElementsEnabled: data.paypalElementsEnabled,
          paypalNativeFallbackEnabled: data.paypalNativeFallbackEnabled,
          isPurchaseAvailable: data.isPurchaseAvailable
        }))
      }
    } catch (error: any) {
      logEvent(AnalyticEvents.FETCH_STRIPE_PRICES_ERROR, { error })
      Sentry.captureException(error)
      showToast('error', t('errors.unknownError'))
    }
  }

  const getCurrency = () => {
    const searchParams = localStorage.getItem('searchParams')
    if (searchParams) {
      // Convert the stored string back into a URLSearchParams object
      const urlParams = new URLSearchParams(searchParams)

      // Now you can get the values of specific keys
      const currency = urlParams.get('currency')
      const curr = urlParams.get('curr')

      return currency ?? curr ?? undefined
    }
  }

  useEffect(() => {
    const localStorageAppState = localStorage.getItem('appState')
    const hasStripePrices = localStorageAppState?.includes('stripePrices')
    if (localStorageAppState) {
      setAppState(JSON.parse(localStorageAppState))
    }

    if (!hasStripePrices) {
      shouldLoadPackages.current = true
      void initStorage()
    }
  }, [])

  useEffect(() => {
    const convertedAppState = JSON.stringify(appState)
    localStorage.setItem('appState', convertedAppState)

    if (!convertedAppState.includes('stripePrices')) {
      void initStorage()
    }

    if (!appState.currentStep && (appState.steps && appState.steps.length > 0)) {
      history.push(RouteNames.LOADING)
    }

    // I always want to have a tokenId in the localStorage
    // so we can identify the user properly on the registration screen
    void getUserIdToken().then((tokenId) => {
      if (!tokenId) {
        localStorage.removeItem('tokenId')
      } else {
        localStorage.setItem('tokenId', tokenId)
      }
    })

    saveLastUpdatedTime()
  }, [appState])

  const getUserIdToken = async () => {
    const firebase = await firebaseServicePromise
    try {
      const tokenId = await firebase.getIdToken()
      return tokenId
    } catch (error: any) {
      return null
    }
  }

  const saveLastUpdatedTime = () => {
    const lastUpdatedTime = new Date().getTime()
    localStorage.setItem('lastUpdatedTime', lastUpdatedTime.toString())
  }

  const clearAppState = () => {
    localStorage.removeItem('appState')
    localStorage.removeItem('countryCode')
    shouldLoadPackages.current = true
    setAppState({})
  }

  return (
    <AppStateContext.Provider value={{ appState, setAppState, initStorage, clearAppState }}>
      {children}
    </AppStateContext.Provider>
  )
}

export { AppStateProvider, useAppState }
