import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { title } from './Consts'

export function getPerfectWeight(t: TFunction): Step {
  const perfectWeight = 'perfect_weight'

  return {
    id: perfectWeight,
    type: 'weight',
    wordings: {
      title: t(`${perfectWeight}.${title}`),
      placeholder: ''
    }
  }
}
