import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getLastHappyWeight(t: TFunction): Step {
  const lastHappyWeight = 'last_happy_weight'
  const lessThan1Year = 'less_than_1_year'
  const oneTo2Years = '1_to_2_years'
  const moreThan2Years = 'more_than_2_years'
  const never = 'never'

  return {
    id: 'last_happy_weight',
    type: 'single-select',
    wordings: {
      title: t(`${lastHappyWeight}.${title}`)
    },
    options: [
      {
        id: lessThan1Year,
        label: t(`${lastHappyWeight}.${options}.${lessThan1Year}`),
        emoji: '⌛'
      },
      {
        id: oneTo2Years,
        label: t(`${lastHappyWeight}.${options}.${oneTo2Years}`),
        emoji: '📆'
      },
      {
        id: moreThan2Years,
        label: t(`${lastHappyWeight}.${options}.${moreThan2Years}`),
        emoji: '🗓️'
      },
      {
        id: never,
        label: t(`${lastHappyWeight}.${options}.${never}`),
        emoji: '😔'
      }
    ]
  }
}
