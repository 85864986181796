import { BodyFastAction } from 'Reducers'
import { ActionType } from './ActionTypes'
import { DefaultConfig } from 'Lib/Constants'
import { DateTime } from 'luxon'

type DiscountBannerType = 'gift' | 'timer'

export interface DiscountState {
  offerDiscount: boolean
  discountExpiresAt: number | null
  pauseTimerOffset: number | null
  discountBannerType: DiscountBannerType
}

export interface IDiscountTimerStart { type: ActionType.START_DISCOUNT_TIMER }
export interface IDiscountPauseOffset { type: ActionType.SET_PAUSE_DISCOUNT_OFFSET, offsetSeconds: number }
export interface IDiscountSetOfferDiscount { type: ActionType.SET_OFFER_DISCOUNT, offerDiscount: boolean }
export interface IDiscountSetBannerType { type: ActionType.SET_DISCOUNT_BANNER_TYPE, discountBannerType: DiscountBannerType }
export interface IResetDiscountTimer { type: ActionType.RESET_DISCOUNT_TIMER }

export type DiscountAction =
  | IDiscountTimerStart
  | IDiscountPauseOffset
  | IDiscountSetOfferDiscount
  | IDiscountSetBannerType
  | IResetDiscountTimer

const INITIAL_STATE: DiscountState = {
  offerDiscount: DefaultConfig.OFFER_DISCOUNT,
  discountExpiresAt: null,
  pauseTimerOffset: null,
  discountBannerType: 'timer',
}

const startDiscountTimer = (state: DiscountState) => {
  const { discountExpiresAt, pauseTimerOffset } = state

  return discountExpiresAt === null
    ? { ...state, pauseTimerOffset: null, discountExpiresAt: DateTime.now().plus({ seconds: DefaultConfig.DISCOUNT_COUNTDOWN_DURATION_SECONDS }).toUnixInteger() }
    : { ...state, pauseTimerOffset: null, discountExpiresAt: discountExpiresAt + (pauseTimerOffset ?? 0) }
}

export const DiscountReducer = (
  state: DiscountState = INITIAL_STATE,
  action: BodyFastAction
): DiscountState => {
  switch (action.type) {
    case ActionType.START_DISCOUNT_TIMER: {
      return startDiscountTimer(state)
    }
    case ActionType.SET_PAUSE_DISCOUNT_OFFSET: {
      return {
        ...state,
        pauseTimerOffset: action.offsetSeconds,
      }
    }
    case ActionType.SET_OFFER_DISCOUNT: {
      return {
        ...state,
        offerDiscount: action.offerDiscount,
      }
    }
    case ActionType.RESET_DISCOUNT_TIMER: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
