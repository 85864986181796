import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getWorkActivityLevel(t: TFunction): Step {
  const workActivityLevel = 'work_activity_level'
  const inactive = 'inactive'
  const active = 'active'
  const bitOfBoth = 'bit_of_both'

  return {
    id: workActivityLevel,
    type: 'single-select',
    wordings: {
      title: t(`${workActivityLevel}.${title}`)
    },
    options: [
      {
        id: inactive,
        label: t(`${workActivityLevel}.${options}.${inactive}`),
        emoji: '🪑'
      },
      {
        id: active,
        label: t(`${workActivityLevel}.${options}.${active}`),
        emoji: '🏃'
      },
      {
        id: bitOfBoth,
        label: t(`${workActivityLevel}.${options}.${bitOfBoth}`),
        emoji: '🚶'
      }
    ]
  }
}
