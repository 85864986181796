import { ONBOARDING_STEPS } from 'types/onboardingTypes'
import { getUserCountry } from './Localization'

interface UserDataFirebaseFormat {
  personal: {
    personalGoals: PersonalGoalIds[]
    initialExperience: InitialExperience
    goalWeight: number
    height: number
    unitSystem: IUnitSystem
    unitSystemWater: IUnitSystemWater
    gender: IGender | null
    age: number | null
    ageRange: AgeRange | null
  }
  stats: {
    weight: WeightStat[]
  }
}

interface WeightStat {
  date: string
  value: number
}

interface Selections {
  [stepId: string]: any
}

export const convertToFirebaseFormat = (selections: Selections): Selections => {
  try {
    const country = getUserCountry()
    const initialExperience = convertFastingKnowledgeToInitialExperience(selections.fasting_knowledge)
    const personalGoals = convertPersonalGoals(selections.goals)
    const gender: IGender = selections.gender
    const goalWeight = selections.perfect_weight.inKg.value
    const weight = selections.weight.inKg.value
    const height = selections.height.inCm.value
    const unitSystem = selections.weight.original.unit
    const unitSystemWater = ['US'].includes(country) ? 'Oz' : 'L'
    const age = selections.age
    const ageRange = selections.age_range

    if (!initialExperience || !personalGoals || !gender || !goalWeight || !weight || !height || !unitSystem) {
      throw new Error('Invalid data')
    }

    const currentDate = new Date()
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
    // Create new user data with the format required for Firebase
    const userDataFirebaseFormat: UserDataFirebaseFormat = {
      personal: {
        personalGoals,
        initialExperience,
        goalWeight,
        height,
        unitSystem,
        unitSystemWater,
        gender,
        age,
        ageRange
      },
      stats: {
        weight: [
          {
            date: formattedDate,
            value: weight
          }
        ]
      }
    }

    return userDataFirebaseFormat
  } catch (error) {
    throw new Error('Invalid data')
  }
}

function convertFastingKnowledgeToInitialExperience(fastingKnowledge: FastingKnowledge): InitialExperience {
  if (fastingKnowledge === 'nothing') {
    return 'beginner'
  } else if (fastingKnowledge === 'a_bit') {
    return 'advanced'
  } else if (fastingKnowledge === 'expert') {
    return 'expert'
  }

  return 'beginner'
}

function convertPersonalGoals(goals: Goal[]): PersonalGoalIds[] {
  const personalGoalIds: PersonalGoalIds[] = []

  for (const goal of goals) {
    if (goal === 'lose_weight') {
      personalGoalIds.push('loseWeight')
    } else if (goal === 'improve_sleep') {
      personalGoalIds.push('improveHealth')
    } else if (goal === 'improve_overall_health') {
      personalGoalIds.push('improveHealth')
    } else if (goal === 'boost_energy') {
      personalGoalIds.push('moreEnergy')
    } else if (goal === 'strengthen_cognitive_functions') {
      personalGoalIds.push('mentalClarity')
    } else if (goal === 'support_longevity') {
      personalGoalIds.push('liveLonger')
    }
  }

  return personalGoalIds
}

// Remove all the undefined values from an object
export function removeUndefined(obj: any): Selections {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeUndefined(obj[key])
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    else if (obj[key] === undefined) delete obj[key]
  })
  return obj
}

export function getLastSavedStep(): ONBOARDING_STEPS | null {
  const lastSavedStep = localStorage.getItem('lastSavedStep')

  if (!lastSavedStep) {
    return null
  }

  return lastSavedStep as ONBOARDING_STEPS
}

export function saveLastSavedStep(step: ONBOARDING_STEPS): void {
  localStorage.setItem('lastSavedStep', step)
}

export const getImperialHeight = (heightInCm: number): FeetInchStruct => {
  return centimetersToFeetInch(heightInCm)
}

export const convertKgToLbs = (kg: number) => {
  return kg * 2.20462
}

export const round6 = (value: number): number => Math.round(value * 1000000) / 1000000
export const round2 = (value: number): number => Math.round(value * 100) / 100
export const round1 = (value: number): number => Math.round(value * 10) / 10
export const round0 = (value: number): number => Math.round(value)

export const centimetersToInch = (cm: number): number => cm * 0.393701
export const centimetersToFeet = (cm: number): number => cm * 0.032808
export const centimetersToFeetInch = (cm: number): FeetInchStruct => {
  const feetTotal = cm * 0.032809 // technically it's 0.032808, but this converts 4ft0 to 3ft11.9999
  const feet = Math.trunc(feetTotal)
  const inch = round0((feetTotal - feet) * 12)
  return { feet, inch }
}

export const inchToCentimeters = (inch: number): number => inch * 2.54
export const feetToCentimeters = (ft: number): number => ft * 30.48
export const feetInchToCentimeters = ({ feet, inch }: FeetInchStruct): number =>
  (feet + inch / 12) * 30.48

export const removeLeadingZeros = (value: string): string => {
  return value.replace(/^0+/, '')
}
