import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'

export function existingEmailLogin(t: TFunction): Step {
  return {
    id: 'existing_email_login',
    type: 'custom',
    wordings: {
      title: ''
    }
  }
}
