import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getHomeOrOutside(t: TFunction): Step {
  const homeOrOutside = 'home_or_outside'
  const home = 'home'
  const outside = 'outside'
  const both = 'both'

  return {
    id: homeOrOutside,
    type: 'single-select',
    wordings: {
      title: t(`${homeOrOutside}.${title}`)
    },
    options: [
      {
        id: home,
        label: t(`${homeOrOutside}.${options}.${home}`),
        emoji: '🏠'
      },
      {
        id: outside,
        label: t(`${homeOrOutside}.${options}.${outside}`),
        emoji: '🧑‍🍳'
      },
      {
        id: both,
        label: t(`${homeOrOutside}.${options}.${both}`),
        emoji: '🍲'
      }
    ]
  }
}
