import { BadgePackageTagId, CustomPackage, PackageOrderStyle, PackageTagId, PaymentColorPackage, PaymentColorScheme, PromotionLabelStyle } from 'types/onboardingTypes'
import { getUserLanguage } from './Localization'
import { TFunction } from 'i18next'
import { ZERO_DECIMAL_CURRENCIES } from 'Lib/Constants'
import { isDesktop } from 'react-device-detect'
import { theme } from 'styles/theme'
import { images } from 'images'

const daysPerIntervals = new Map<number, number>([
  [1, 30.4], // Average month is 30.4 days
  [12, 365],
  [3, 92],
  [6, 180]
])

export function mapTagIdToBadgePackageTagId(tagId?: BadgePackageTagId): PackageTagId | undefined {
  switch (tagId) {
    case 'best-value':
      return PackageTagId.BestValue
    case 'most-popular':
      return PackageTagId.MostPopular
    case 'fast-track':
      return PackageTagId.FastTrack
  }
}

export const formatPriceWithoutCurrency = (unitPrice: number, templatePriceString: string): string | undefined => {
  const tokens = templatePriceString.match(/^(\D*)[\d,. ]+([.,])\d\d(\D*)$/)!
  const decSeparator = tokens[2]
  const thousandsSeparator = decSeparator === ',' ? '.' : ','

  const priceString = String(unitPrice)
    .padStart(3, '0')
    .replace(/(\d)(\d\d\d\d\d)$/, '$1' + thousandsSeparator + '$2')
    .replace(/(\d\d)$/, decSeparator + '$1')

  return `${priceString}`
}

const formatTrialPackage = (mainItem: IStripePrice, t: TFunction, trialItem: IStripePrice, discountAvailable?: boolean): CustomPackage => {
  const price = trialItem.unit_amount / 100
  const discountPercentage = Number(trialItem.metadata.baseDiscountPercentage) / 100
  const discountedItemPrice = trialItem?.unit_amount && discountAvailable ? (trialItem?.unit_amount / 100) * (1 - discountPercentage) : undefined

  const tagId = PackageTagId.Trial
  const daysPerInterval = 7

  const pricePerDay = (price / daysPerInterval)
  const discountedPricePerDay = discountedItemPrice ? (discountedItemPrice / daysPerInterval) : undefined

  return ({
    id: trialItem.id,
    name: t('package.trial'),
    price: price,
    pricePerDay: pricePerDay,
    numberOfPeriods: 1,
    tagId: tagId,
    discountedPrice: discountedItemPrice,
    discountedPricePerDay: discountedPricePerDay,
    priceInCents: trialItem.unit_amount,
    discountedPriceInCents: discountedItemPrice ? Math.round(discountedItemPrice * 100) : undefined,
    interval: 'week',
    currency: trialItem.currency.toUpperCase()
  })
}

const formatPricePackage = (item: IStripePrice, promotionLabelStyle: PromotionLabelStyle, t: TFunction, discountAvailable?: boolean): CustomPackage => {
  const price = item.unit_amount / 100
  const interval = item.recurring.interval

  const discountPercentage = Number(item.metadata.baseDiscountPercentage) / 100
  const discountedItemPrice = discountAvailable && !isNaN(discountPercentage) ? (item.unit_amount / 100) * (1 - discountPercentage) : undefined

  let tag
  let tagId!: PackageTagId | undefined
  let daysPerInterval = 1

  const useOriginalPackageLabels = promotionLabelStyle === 'original' || item.metadata.group !== 'v8'
  const popularTag = useOriginalPackageLabels ? t('package.tags.popular') : t('package.tags.mostPopular')
  const fastTrackOrBestPriceTag = useOriginalPackageLabels ? t('package.tags.bestPrice') : t('package.tags.fastTrack')

  const popularTagId = PackageTagId.MostPopular
  const fastTrackOrBestPriceTagId = useOriginalPackageLabels ? PackageTagId.BestValue : PackageTagId.FastTrack

  if (interval === 'month') {
    daysPerInterval = daysPerIntervals.get(item.recurring.interval_count)!

    if (item.recurring.interval_count === 3) {
      if (useOriginalPackageLabels) {
        tag = item.metadata.group === 'v8' ? fastTrackOrBestPriceTag : popularTag
        tagId = item.metadata.group === 'v8' ? fastTrackOrBestPriceTagId : popularTagId
      } else {
        tag = item.metadata.group === 'v8' ? popularTag : fastTrackOrBestPriceTag
        tagId = item.metadata.group === 'v8' ? popularTagId : fastTrackOrBestPriceTagId
      }
    } else {
      if (useOriginalPackageLabels) {
        tag = item.metadata.group === 'v8' ? popularTag : undefined
        tagId = item.metadata.group === 'v8' ? popularTagId : undefined
      } else {
        tag = item.metadata.group === 'v8' ? fastTrackOrBestPriceTag : undefined
        tagId = item.metadata.group === 'v8' ? fastTrackOrBestPriceTagId : undefined
      }
    }
  } else if (interval === 'year') {
    if (useOriginalPackageLabels) {
      tag = fastTrackOrBestPriceTag
      tagId = fastTrackOrBestPriceTagId
      daysPerInterval = daysPerIntervals.get(12)!
    } else {
      tag = popularTag
      tagId = popularTagId
      daysPerInterval = daysPerIntervals.get(12)!
    }
  }

  const pricePerDay = (price / daysPerInterval)
  const discountedPricePerDay = discountedItemPrice ? (discountedItemPrice / daysPerInterval) : undefined
  const intervalCount = interval === 'year' ? item.recurring.interval_count * 12 : item.recurring.interval_count

  let name = ''
  if (interval === 'year') {
    name = t(intervalCount === 12 ? 'package.yearly' : 'package.yearly_plural', { intervalCount: item.recurring.interval_count })
  } else if (interval === 'month') {
    name = t(intervalCount === 1 ? 'package.monthly' : 'package.monthly_plural', { intervalCount })
  }

  return ({
    id: item.id,
    name: name,
    price: price,
    priceInCents: item.unit_amount,
    pricePerDay: pricePerDay,
    numberOfPeriods: intervalCount,
    tag: tag,
    tagId: tagId,
    discountedPrice: discountedItemPrice,
    discountedPriceInCents: discountedItemPrice ? Math.round(discountedItemPrice * 100) : undefined,
    discountedPricePerDay: discountedPricePerDay,
    interval: item.recurring.interval,
    currency: item.currency.toUpperCase()
  })
}

export const getPaymentScreenColorPackage = (colorScheme: PaymentColorScheme): PaymentColorPackage => {
  switch (colorScheme) {
    case 'original':
      return {
        bannerColor: theme.colors.secondary,
        bannerTextColor: theme.colors.text,
        packageColorDark: theme.colors.secondary,
        packageColorLight: theme.colors.secondaryLight,
        selectedPriceColor: '#fee7cf',
        selectedPriceTextColor: theme.colors.text,
        buttonColor: theme.colors.primary,
        selectedLeftRoundedArrow: images.SelectedLeftRoundedArrow,
        selectedLeftRoundedArrowLight: images.SelectedLeftRoundedArrow,
        scale: images.scale,
        statusLow: images.statusLow,
        statusMedium: images.statusMedium
      }
    case 'classic':
      return {
        bannerColor: theme.colors.primaryDark,
        bannerTextColor: theme.colors.white,
        packageColorDark: theme.colors.primaryDark,
        packageColorLight: theme.colors.primary,
        buttonColor: theme.colors.primary,
        selectedPriceColor: theme.colors.primaryDark,
        selectedPriceTextColor: theme.colors.white,
        selectedLeftRoundedArrow: images.SelectedLeftRoundedArrowClassic,
        selectedLeftRoundedArrowLight: images.SelectedLeftRoundedArrowClassicLight,
        scale: images.scaleClassic,
        statusLow: images.statusLowClassic,
        statusMedium: images.statusMediumClassic
      }
    case 'blue':
      return {
        bannerColor: theme.colors.packageDarkBlue,
        bannerTextColor: theme.colors.white,
        packageColorDark: theme.colors.packageDarkBlue,
        packageColorLight: theme.colors.packageLightBlue,
        buttonColor: theme.colors.packageLightBlue,
        selectedPriceColor: theme.colors.packageDarkBlue,
        selectedPriceTextColor: theme.colors.white,
        selectedLeftRoundedArrow: images.SelectedLeftRoundedArrowBlue,
        selectedLeftRoundedArrowLight: images.SelectedLeftRoundedArrowBlueLight,
        scale: images.scaleBlue,
        statusLow: images.statusLowBlue,
        statusMedium: images.statusMediumBlue
      }
    case 'green':
      return {
        bannerColor: theme.colors.packageDarkGreen,
        bannerTextColor: theme.colors.white,
        packageColorDark: theme.colors.packageDarkGreen,
        packageColorLight: theme.colors.packageLightGreen,
        buttonColor: theme.colors.packageLightGreen,
        selectedPriceColor: theme.colors.packageDarkGreen,
        selectedPriceTextColor: theme.colors.white,
        selectedLeftRoundedArrow: images.SelectedLeftRoundedArrowGreen,
        selectedLeftRoundedArrowLight: images.SelectedLeftRoundedArrowGreenLight,
        scale: images.scaleGreen,
        statusLow: images.statusLowGreen,
        statusMedium: images.statusMediumGreen
      }
    case 'orange':
      return {
        bannerColor: theme.colors.secondaryLight,
        bannerTextColor: theme.colors.white,
        packageColorDark: theme.colors.secondaryLight,
        packageColorLight: theme.colors.secondary,
        buttonColor: theme.colors.secondary,
        selectedPriceColor: theme.colors.secondaryLight,
        selectedPriceTextColor: theme.colors.white,
        selectedLeftRoundedArrow: images.SelectedLeftRoundedArrowOrange,
        selectedLeftRoundedArrowLight: images.SelectedLeftRoundedArrowOrangeLight,
        scale: images.scale,
        statusLow: images.statusLow,
        statusMedium: images.statusMedium
      }
    default:
      throw new Error(`Invalid payment color scheme: ${colorScheme}`)
  }
}

export const getPaymentScreenDarkColor = (colorScheme: PaymentColorScheme): string => {
  switch (colorScheme) {
    case 'blue':
      return theme.colors.packageDarkBlue
    case 'green':
      return theme.colors.packageDarkGreen
    case 'classic':
      return theme.colors.primaryDark
    case 'orange':
    case 'original':
      return theme.colors.secondaryLight
    default:
      throw new Error(`Invalid payment color scheme: ${colorScheme}`)
  }
}

export const getPaymentScreenLightColor = (colorScheme: PaymentColorScheme): string => {
  switch (colorScheme) {
    case 'blue':
      return theme.colors.packageLightBlue
    case 'green':
      return theme.colors.packageLightGreen
    case 'classic':
      return theme.colors.primary
    case 'orange':
    case 'original':
      return theme.colors.secondary
    default:
      throw new Error(`Invalid payment color scheme: ${colorScheme}`)
  }
}
export const formatStripePackage = (stripePackage: {
  prices: IStripePrice[]
  isEU: boolean
  countryCode?: string | undefined
  currency: string
} | undefined, promotionLabelStyle: PromotionLabelStyle, t: any, discountAvailable: boolean, stripePackageOrder: PackageOrderStyle) => {
  const filteredByActive = stripePackage?.prices.filter((item: IStripePrice) => item.active)
  const displayItems = filteredByActive?.filter((item: IStripePrice) => (item.type === 'recurring' && item.recurring.interval_count !== 6) || item.metadata.group === 'v8')

  const formattedPackages = displayItems?.map<CustomPackage>((item: IStripePrice) => {
    if (item.metadata.group === 'v8' && !item.recurring) {
      const oneMonthRecurringPackage = filteredByActive?.find((filteredItem) => filteredItem.type === 'recurring' && filteredItem.recurring.interval_count === 1)

      return formatTrialPackage(oneMonthRecurringPackage!, t, item, discountAvailable)
    }

    return formatPricePackage(item, promotionLabelStyle, t, discountAvailable)
  })

  // Sort the packages by price
  const sortedPackages = formattedPackages?.sort((a, b) => a.price - b.price)

  // Create a map for easy access to packages by their price level
  switch (stripePackageOrder) {
    case 'original':
      return sortedPackages
    case 'reversed':
      return sortedPackages?.reverse()
  }
}

export const getDiscountPercentage = (pricePackage: CustomPackage): number => {
  return pricePackage.discountedPrice ? Math.round((1 - (pricePackage.discountedPrice / pricePackage.price)) * 100) : 0
}

export const isMobile = () => {
  const userAgent = window.navigator.userAgent
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
}

export function isIos() {
  const userAgent = window.navigator.userAgent
  return /iPhone|iPad|iPod/i.test(userAgent)
}

export function isAndroid() {
  const userAgent = window.navigator.userAgent
  return /Android/i.test(userAgent)
}

// Facebook | Instagram | FB Messenger | Twitter | TikTok
export function checkIfIsEmbeddedBrowser() {
  const userAgent = window.navigator.userAgent
  return /FBAV|Instagram|\bFB[\w_]+\/(Messenger|MESSENGER)|Twitter|TikTok/i.test(userAgent)
}

export function checkIfIsAppleDevice() {
  const userAgent = window.navigator.userAgent
  return /Mac|iPod|iPhone|iPad/.test(userAgent)
}

export function getPlatform(): IPlatform {
  if (isIos()) {
    return 'ios'
  } else if (isAndroid()) {
    return 'android'
  } else if (isDesktop) {
    return 'desktop'
  }
  return 'other'
}

interface FormatPriceOptions {
  price: number
  currency: string
  withFractionDigits: boolean
  currencyDisplay?: Intl.NumberFormatOptions['currencyDisplay']
}

export const formatPriceString = ({ price, currency, withFractionDigits, currencyDisplay = 'code' }: FormatPriceOptions) => {
  // zero decimal currencies are returned directly the 'integral' value without the cents by stripe, so the cents need to be added manually by multiplying it by 100
  const isZeroDecimalCurrency = ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())

  const roundedPrice = ZERO_DECIMAL_CURRENCIES.includes(currency) ? price * 100 : Math.round(price * 100) / 100
  const formatOptions: Intl.NumberFormatOptions = {
    currency,
    currencyDisplay,
    style: 'currency'
  }

  formatOptions.minimumFractionDigits = !isZeroDecimalCurrency && withFractionDigits ? 2 : 0
  formatOptions.maximumFractionDigits = !isZeroDecimalCurrency && withFractionDigits ? 2 : 0

  return Intl.NumberFormat(getUserLanguage(), formatOptions).format(roundedPrice)
}

export const formatTimeLeft = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}
