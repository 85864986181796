// 5 days until a cancelled subscription gets deactivated (1 day on non-production systems)
const COOLDOWN_PERIOD: number = process.env.NODE_ENV !== 'production' ? 1 * 24 * 60 * 60 * 1000 : 5 * 24 * 60 * 60 * 1000

export const getGoalWeightDate = (goalWeight: IUserWeight, currentWeight: IUserWeight) => {
  let weightLossPerWeek = 0

  if (currentWeight.inKg.value - goalWeight.inKg.value < 5) {
    weightLossPerWeek = 0.5
  } else {
    weightLossPerWeek = 0.75
  }

  const totalWeeks = Math.ceil((currentWeight.inKg.value - goalWeight.inKg.value) / weightLossPerWeek)

  const goalDate = new Date()
  goalDate.setDate(goalDate.getDate() + totalWeeks * 7)

  return goalDate
}

// Merge two objects with the same structure without overwriting the values
export function deepMerge(obj1: any, obj2: any) {
  const output = { ...obj1 }

  Object.keys(obj2).forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj2[key] && typeof obj2[key] === 'object' && obj1.hasOwnProperty(key) && obj1[key] && typeof obj1[key] === 'object') {
      output[key] = deepMerge(obj1[key], obj2[key])
    } else {
      output[key] = obj2[key]
    }
  })

  return output
}

export const isUserSubscribed = (user: IUser): boolean => {
  if (!user.subscription) {
    return false
  }

  const lastIapLogEntry = user.iapLog?.slice(-1)[0]

  if (
    lastIapLogEntry?.type === 'SUBSCRIPTION_EXPIRED' || // ANDROID
    lastIapLogEntry?.type === 'SUBSCRIPTION_PAUSED' || // ANDROID
    lastIapLogEntry?.type === 'SUBSCRIPTION_ON_HOLD' || // ANDROID
    lastIapLogEntry?.type === 'EXPIRED' || // IOS
    lastIapLogEntry?.type === 'GRACE_PERIOD_EXPIRED' || // IOS. See docs: https://developer.apple.com/documentation/appstoreservernotifications/notificationtype
    (lastIapLogEntry?.type === 'DID_FAIL_TO_RENEW' && lastIapLogEntry?.subtype !== 'GRACE_PERIOD')
  ) {
    return false
  }

  return !!user.subscription
}
