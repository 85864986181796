import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getBreakfastTime(t: TFunction): Step {
  const breakfastTime = 'breakfast_time'
  const before7am = 'before7am'
  const between7and9 = 'between7and9'
  const after9 = 'after9'
  const skip = 'skip'

  return {
    id: breakfastTime,
    type: 'single-select',
    wordings: {
      title: t(`${breakfastTime}.${title}`)
    },
    options: [
      {
        id: before7am,
        label: t(`${breakfastTime}.${options}.${before7am}`),
        emoji: '🕕'
      },
      {
        id: between7and9,
        label: t(`${breakfastTime}.${options}.${between7and9}`),
        emoji: '🕖'
      },
      {
        id: after9,
        label: t(`${breakfastTime}.${options}.${after9}`),
        emoji: '🕘'
      },
      {
        id: skip,
        label: t(`${breakfastTime}.${options}.${skip}`),
        emoji: '❌'
      }
    ]
  }
}
