import { ActionType } from 'Reducers'
import { eventChannel, END } from 'redux-saga'

import { take, call, put, cancelled, all } from 'redux-saga/effects'

function countdown (secs: number) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      secs += 1
      if (secs > 0) {
        emitter(secs)
      } else {
        emitter(END)
        clearInterval(iv)
      }
    }, 1000)

    return () => {
      clearInterval(iv)
    }
  }
  )
}

export default function * timerSaga (): Generator<any, void, any> {
  const chan = yield call(countdown, 0)

  try {
    while (true) {
      const elapsedSeconds = yield take(chan)
      yield put({ type: ActionType.TIMER_TICK, elapsedSeconds })
    }
  } finally {
    if (yield cancelled()) {
      chan.close()
    }

    yield put({ type: ActionType.TIMER_STOP })
  }
}
export function * TimerSagas() {
  yield all([
    call(timerSaga)
  ])
}
