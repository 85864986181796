import { getSearchParams, removeSearchParamsFromLocalStorage } from 'Lib'
import { DefaultConfig } from 'Lib/Constants'
import { ActionType, Selectors } from 'Reducers'
import { put } from 'redux-saga/effects'
import { all, select, takeLatest } from 'typed-redux-saga'

function * mapSearchParamsSaga() {
  const searchParams = getSearchParams()
  const searchParamsOfferDiscount = !!searchParams.get('dc') || DefaultConfig.OFFER_DISCOUNT

  removeSearchParamsFromLocalStorage(['dc'])

  if (searchParamsOfferDiscount) {
    yield put({ type: ActionType.SET_OFFER_DISCOUNT, offerDiscount: searchParamsOfferDiscount })
  }
}

function * handleLogout() {
  yield put({ type: ActionType.RESET_DISCOUNT_TIMER })
}

function * handlePausedDiscountTimer() {
  const offset: number = yield select(Selectors.getPauseTimerOffset)

  if (typeof offset === 'number') {
    yield put({ type: ActionType.SET_PAUSE_DISCOUNT_OFFSET, offsetSeconds: offset + 1 })
  }
}

export function * DiscountSagas() {
  yield all([
    takeLatest(ActionType.FETCH_DISCOUNT_SEARCH_PARAMS, mapSearchParamsSaga),
    takeLatest(ActionType.LOG_OUT_REQUEST, handleLogout),
    takeLatest(ActionType.TIMER_TICK, handlePausedDiscountTimer)
  ])
}
