import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'

export function getOnePlan(t: TFunction): Step {
  return {
    id: 'the_one_plan',
    type: 'information',
    allowNext: true,
    wordings: {
      title: t('the-one-plan.title'),
      subtitle: t('the-one-plan.subtitle')
    }
  }
}
