import * as Sentry from '@sentry/react'
import { useStripe, Elements } from '@stripe/react-stripe-js'

import { useAppState } from 'AppContextProvider'
import { PaypalButton } from 'Components/PaypalButton.component'
import {
  formatPriceString,
  getUserCountry,
  getUserLanguage,
  getUserLocaleForDateFns
} from 'Lib'
import { AnalyticEvents } from 'Lib/Constants'
import dateFormatter from 'date-fns/format'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { CustomPackage, PackageTagId } from 'types/onboardingTypes'
import ApplePayButton from './ApplePayButton'
import CreditCardButton from './CreditCardButton'
import GooglePay from './GooglePayButton'
import PricePerDay from './PricePerDay'
import TotalPrice from './TotalPrice'
import { Stripe, StripeElementLocale, StripeElementsOptions, StripeError } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import BottomSliderModal from 'Components/BottomSliderModal'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { showToast } from 'Components/Toast'

export const PAYPAL_FIRST_BUTTON_COUNTRIES = ['DE', 'AT', 'CH']

const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  text-align: center;
  margin: ${props => props.theme.spacing.large}
    ${props => props.theme.spacing.zero}
    ${props => props.theme.spacing.medium};
  color: ${({ theme }) => theme.colors.text};
`

const OptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.medium};
  gap: ${props => props.theme.spacing.small};
`

const Disclaimer = styled.div<{ marginTop?: string }>`
  font-size: ${props => props.theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.lightText};
  text-align: center;
  margin-bottom: ${props => props.theme.spacing.medium};
  margin-top: ${({ marginTop }) => marginTop};
`
const LoadingWrapper = styled.div`
  height: 44px;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex: 1;
`

const Content = styled.div`
  margin-top: ${props => props.theme.spacing.medium};
`
const SmallSpacer = styled.div`
height: ${props => props.theme.spacing.small};
`

const STRIPE_ELEMENTS_APPEARANCE: StripeElementsOptions['appearance'] = {
  theme: 'flat',
  variables: {
    fontFamily: 'Fira Sans, sans-serif',
    colorPrimary: theme.colors.primary,
    colorPrimaryText: theme.colors.text,
    colorBackgroundText: theme.colors.lightText,
    fontSizeBase: theme.fontSizes.medium,
    fontLineHeight: '1.1',
    spacingAccordionItem: theme.spacing.medium,
  },
  rules: {
    '.AccordionItem': {
      marginBottom: theme.spacing.xsmall
    },
    '.Tab': {
      border: `1px solid ${theme.colors.lightGray}`,
      backgroundColor: 'white'
    },
    '.Tab--selected': {
      border: `3px solid ${theme.colors.primary}`,
      backgroundColor: 'white'
    },
    '.TabIcon--selected': {
      fill: theme.colors.primary
    },
    '.TabLabel--selected': {
      color: theme.colors.primary
    },
    '.Text--redirect': {
      fontSize: '0px'
    },
    '.Block': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: theme.colors.transparent,
      paddingBottom: '0px'
    },
    '.Label': {
      fontSize: '0px'
    }
  }
}

interface BuyPackageModalProps {
  onClose: () => void
  selectedPackageId: string
  email: string
  externalStripeError?: StripeError
}

const BuyPackageModal: React.FC<BuyPackageModalProps> = ({ onClose, selectedPackageId, email, externalStripeError }) => {
  const checkoutMode = useFeatureValue('checkout-mode', 'embeddedForm' /* fallback value */)
  // const checkoutMode = useRemoteConfigString('web_checkoutMode') // We use growthbook

  const { t } = useTranslation()
  const {
    appState: {
      paypalCheckoutEnabled,
      paypalNativeFallbackEnabled,
      paypalElementsEnabled,
      stripePrices
    }
  } = useAppState()
  const stripe = useStripe()

  const selectedPackage = stripePrices!.find((pkg: CustomPackage) => pkg.id === selectedPackageId)!

  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false)
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false)
  const [isStripeAvailable, setIsStripeAvailable] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const sliderModalRef = useRef()

  const country = localStorage.getItem('countryCode') ?? ''

  const language = getUserLanguage()

  const stripeAmount = selectedPackage.discountedPriceInCents ?? selectedPackage.priceInCents
  const stripeCurrency = selectedPackage.currency.toLowerCase()

  function sliderModalAutoScrollHandler (): void {
    setTimeout(() => (sliderModalRef.current as any).scrollToBottom(), 300)
  }

  async function prepareHostedCheckoutSession (stripe: Stripe): Promise<void> {
    const country = getUserCountry()
    try {
      const pr = stripe.paymentRequest({
        country,
        currency: stripeCurrency,
        total: {
          label: selectedPackage.name,
          amount: stripeAmount
        }
      })

      // Check the availability of the Payment Request API. -> Only for Wallet-based payments, not stripe in general https://stripe.com/docs/js/payment_request/can_make_payment
      // incognito tabs or users without any wallet information on their Google/Apple Pay accounts won't have the option to pay with either
      const result = await pr.canMakePayment()
      if (result != null) {
        setIsApplePayAvailable(result.applePay)
        setIsGooglePayAvailable(result.googlePay)
      }
      setIsStripeAvailable(true)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      setIsStripeAvailable(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (stripe == null || checkoutMode == null) {
      return
    }
    if (checkoutMode === 'hosted') {
      void prepareHostedCheckoutSession(stripe)
    }
  }, [stripe, checkoutMode])

  const mainPriceString = formatPriceString({ price: selectedPackage.price, currency: selectedPackage.currency, withFractionDigits: true })
  let recurringPackage
  let trialMainPlanPriceString = null
  if (selectedPackage.tagId === PackageTagId.Trial) {
    recurringPackage = stripePrices!.find((price) => price.interval === 'month' && price.numberOfPeriods === 1)
    trialMainPlanPriceString = formatPriceString({ price: recurringPackage!.price, currency: recurringPackage!.currency, withFractionDigits: true })
  }

  const discountedPriceString = selectedPackage.discountedPrice ? formatPriceString({ price: selectedPackage.discountedPrice, currency: selectedPackage.currency, withFractionDigits: true }) : null

  const onError = (error: any, channel: string, planId: string): void => {
    logEvent(AnalyticEvents.WEB_PURCHASE_EXCEPTION, { channel, subscriptionModel: planId, exception: error })

    // We don't want to show an error message if the user closes the PayPal window
    if (channel === 'paypal' && error?.message === '"Window is closed, can not determine type"') {
      return
    }

    showToast('error', t('errors.unknownError', { ns: 'translation' }))
  }

  const stripeElementsOptions: StripeElementsOptions = {
    appearance: STRIPE_ELEMENTS_APPEARANCE,
    amount: stripeAmount,
    currency: stripeCurrency,
    // disable the default elements loader, to allow to use a custom loader
    loader: 'never',
    mode: 'subscription',
    locale: (language as StripeElementLocale),
    paymentMethodTypes: paypalElementsEnabled === true ? ['card', 'paypal'] : ['card'],
    fonts: [{
      // !!! maybe self hosted fonts can be used here
      cssSrc: 'https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap'
    }]
  }

  const periodString =
    (recurringPackage ?? selectedPackage).interval === 'year'
      ? t('year')
      : (recurringPackage ?? selectedPackage).interval === 'month'
        ? (recurringPackage ?? selectedPackage).numberOfPeriods === 1
          ? t('month_one')
          : t('month_other')
        : (recurringPackage ?? selectedPackage).numberOfPeriods === 1
          ? t('week_one')
          : t('week_other')

  let disclaimer
  const showSameDisclaimerTexts = (useFeatureValue('payment-screen-show-same-disclaimer-texts', false))

  if (showSameDisclaimerTexts) {
    /*
      Price calculation:
        - 'trialMainPlanPriceString': Price after trial period, only for trial packages. This is calculated from the recurring packages price.
        - 'mainPriceString': Regular price without discounts.
        - 'discountedPriceString': Price with discounts applied.

      For disclaimer text:
        - Use 'trialMainPlanPriceString' as main price if it exists.
        - Use 'discountedPriceString' as discounted price if it exists, else use 'mainPriceString'.
      Note: For trial packages without a discount, 'trialMainPlanPriceString' may be higher than 'mainPriceString'.
    */
    disclaimer = trialMainPlanPriceString || discountedPriceString
      ? t('package.disclaimerDiscount',
        {
          price: trialMainPlanPriceString ?? mainPriceString,
          discountedPrice: discountedPriceString ?? mainPriceString,
          interpolation: { escapeValue: false }
        })
      : t('package.disclaimer', { price: mainPriceString, interpolation: { escapeValue: false } })
  } else {
    disclaimer = t(
      selectedPackage.interval === 'week' ? 'modal.disclaimerTrial'
        : selectedPackage.interval === 'year' ? 'modal.disclaimerYear'
          : selectedPackage.numberOfPeriods === 1 ? 'modal.disclaimerOneMonth'
            : 'modal.disclaimer',
      {
        price: trialMainPlanPriceString ?? mainPriceString,
        initialPrice: discountedPriceString ?? mainPriceString,
        period: periodString,
        interval: selectedPackage.numberOfPeriods,
        interpolation: { escapeValue: false },
        endDate: dateFormatter(new Date(new Date().setDate(new Date().getDate() + 7)), 'PP', { locale: getUserLocaleForDateFns() }).replace(/ /g, '\u00A0') // non-breaking spaces
      })
  }

  const paypalAsFirstButton = PAYPAL_FIRST_BUTTON_COUNTRIES.includes(country.toUpperCase())

  return checkoutMode === 'embeddedForm'
    ? <BottomSliderModal ref={sliderModalRef} onClose={onClose}>
      <Content>
        <PricePerDay price={formatPriceString({ price: selectedPackage.discountedPricePerDay ?? selectedPackage.pricePerDay, currency: selectedPackage.currency, withFractionDigits: selectedPackage.pricePerDay < 100 })} />
        <TotalPrice
          priceString={mainPriceString}
          discountedPriceString={discountedPriceString}
          selectedPackage={selectedPackage}
          recurringPackage={recurringPackage}
        />
      </Content>
      <Title>{t('modal.chose_a_payment_method')}</Title>
      {paypalNativeFallbackEnabled &&
        <>
          <PaypalButton
            onError={(error) => onError(error, 'paypal', selectedPackage.id)}
            selectedPackage={selectedPackage}
          />
          <SmallSpacer />
        </>}
      <div id='checkout'>
        <Elements options={stripeElementsOptions} stripe={stripe}>
          <CheckoutForm
            selectedPackage={selectedPackage}
            paypalAsFirstButton={paypalAsFirstButton}
            email={email}
            disclaimer={disclaimer}
            onScrollDownRequest={sliderModalAutoScrollHandler}
            externalStripeError={externalStripeError}
          />
        </Elements>
      </div>
    </BottomSliderModal>
    : <BottomSliderModal onClose={onClose}>
      <Content>
        <PricePerDay price={formatPriceString({ price: selectedPackage.discountedPricePerDay ?? selectedPackage.pricePerDay, currency: selectedPackage.currency, withFractionDigits: selectedPackage.pricePerDay < 100 })} />
        <TotalPrice
          priceString={mainPriceString}
          discountedPriceString={discountedPriceString}
          selectedPackage={selectedPackage}
          recurringPackage={recurringPackage}
        />
        <Disclaimer marginTop='10px'>{disclaimer}</Disclaimer>
      </Content>
      <Title>{t('modal.title')}</Title>
      {isLoading ? (
        <LoadingWrapper>
          <ClipLoader
            color={theme.colors.primary}
            size={40}
            speedMultiplier={1} />
        </LoadingWrapper>
      ) : (
        <OptionContainer>
          {paypalCheckoutEnabled && paypalAsFirstButton && <PaypalButton
            onError={(error) => onError(error, 'paypal', selectedPackage.id)}
            selectedPackage={selectedPackage}
          />}
          {isStripeAvailable && <CreditCardButton
            onError={(error) => onError(error, 'creditCard', selectedPackage.id)}
            selectedPackage={selectedPackage}
          />}
          {paypalCheckoutEnabled && !paypalAsFirstButton && <PaypalButton
            onError={(error) => onError(error, 'paypal', selectedPackage.id)}
            selectedPackage={selectedPackage}
          />}
          {isApplePayAvailable && <ApplePayButton
            onError={(error) => onError(error, 'creditCard', selectedPackage.id)}
            selectedPackage={selectedPackage}
          />}
          {isGooglePayAvailable && !isApplePayAvailable && <GooglePay
            onError={(error) => onError(error, 'creditCard', selectedPackage.id)}
            selectedPackage={selectedPackage}
          />}
          {/* </Option> */}
        </OptionContainer>
      )}
    </BottomSliderModal>
}

export default BuyPackageModal
