import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { content, info, title, wordings } from './Consts'

export function getHeight(t: TFunction): Step {
  const height = 'height.'

  return {
    id: 'height',
    type: 'height',
    wordings: {
      title: t(`${height}${wordings}${title}`),
      placeholder: '',
      info: {
        title: t(`${height}${wordings}${info}.${title}`),
        content: t(`${height}${wordings}${info}.${content}`)
      }
    }
  }
}
