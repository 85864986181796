import * as Sentry from '@sentry/react'
import { RouteNames } from 'RouteNames'
import { COMMIT_LOGIN_EVENTS_MAP, COMMIT_ONBOARDING_EVENTS_MAP, GO_BACK_TO_LOGIN_EVENTS_MAP, GO_BACK_TO_ONBOARDING_EVENTS_MAP, GO_TO_LOGIN_EVENTS_MAP, GO_TO_ONBOARDING_EVENTS_MAP, SKIP_ONBOARDING_EVENTS_MAP, STATIC_ROUTE_MAP } from './Constants'
import invariant from 'invariant'

export enum Flows {
  ONBOARDING = 'onboarding',
  LOGIN = 'login',
  STATIC = 'static'
}

const STATIC_ROUTE_NAMES: string[] = [
  RouteNames.PRIVACY,
  RouteNames.COOKIE_POLICY,
  RouteNames.TERMS_OF_USE
]

const LOGIN_ROUTE_NAMES: string[] = [
  RouteNames.LOGIN,
  RouteNames.LOGIN_TOKEN,
  RouteNames.LOGIN_PAYMENT,
  RouteNames.LOGIN_PAYMENT_SUCCESS,
  RouteNames.LOGIN_UPGRADE_ACCOUNT, // Users still can see the upgrade account screen for futoken logins
  RouteNames.LOGIN_CREATE_ACCOUNT,
  RouteNames.LOGIN_DOWNLOAD
]

const ONBOARDING_ROUTE_NAMES: string[] = [
  RouteNames.HOME,
  RouteNames.ONBOARDING,
  RouteNames.PAYMENT,
  RouteNames.UPGRADE_ACCOUNT,
  RouteNames.DOWNLOAD,
  RouteNames.PAYMENT_SUCCESS
]

export const getCurrentFlow = (): Flows => {
  const route = `/${location.pathname.split('/')[1]}`
  if (LOGIN_ROUTE_NAMES.includes(route)) {
    return Flows.LOGIN
  } else if (ONBOARDING_ROUTE_NAMES.includes(route)) {
    return Flows.ONBOARDING
  } else if (STATIC_ROUTE_NAMES.includes(route)) {
    return Flows.STATIC
  } else {
    Sentry.captureException('Unknown flow to path: ' + location.pathname)
    logEvent('web_error_unknown_flow_path', { path: location.pathname })
    return Flows.ONBOARDING
  }
}

// getGoToEventsMap, getCommitEventsMap, getGoBackToEventsMap have the possibility to be called by STATIC flow which shouldn't trigger any events.
// We added a check that returns null in that case, thus the added null return type.

export const getGoToEventsMap = (flow: Flows, eventKey: string): string => {
  invariant(flow !== Flows.STATIC, 'getGoToEventsMap should not be called with STATIC flow')
  return flowToGoToEventsMap[flow][eventKey]
}

export const getCommitEventsMap = (flow: Flows, eventKey: string): string => {
  invariant(flow !== Flows.STATIC, 'getCommitEventsMap should not be called with STATIC flow')
  return flowToCommitEventsMap[flow][eventKey]
}

export const getGoBackToEventsMap = (flow: Flows, eventKey: string): string => {
  invariant(flow !== Flows.STATIC, 'getGoBackToEventsMap should not be called with STATIC flow')
  return flowGoBackToEventsMap[flow][eventKey]
}

export const getSkipEventsMap = (): { [key: string]: string } => {
  const flow = getCurrentFlow()
  return flowToSkipEventsMap[flow]
}

export const getPaymentUrl = (): string => {
  const flow = getCurrentFlow()
  return flowToPaymentUrlMap[flow]
}

export const getPaymentSuccessUrl = (): string => {
  const flow = getCurrentFlow()
  return flowToPaymentSuccessUrlMap[flow]
}

export const getUpgradeAccountUrl = (): string => {
  const flow = getCurrentFlow()
  return flowToUpgradeAccountUrlMap[flow]
}

export const getDownloadUrl = () => {
  const flow = getCurrentFlow()
  return flowToDownloadUrlMap[flow]
}

const flowToPaymentUrlMap: { [key: string]: string } = {
  [Flows.ONBOARDING]: RouteNames.PAYMENT,
  [Flows.LOGIN]: RouteNames.LOGIN_PAYMENT
}

const flowToPaymentSuccessUrlMap: { [key: string]: string } = {
  [Flows.ONBOARDING]: RouteNames.PAYMENT_SUCCESS,
  [Flows.LOGIN]: RouteNames.LOGIN_PAYMENT_SUCCESS
}

const flowToUpgradeAccountUrlMap: { [key: string]: string } = {
  [Flows.ONBOARDING]: RouteNames.UPGRADE_ACCOUNT,
  [Flows.LOGIN]: RouteNames.LOGIN_UPGRADE_ACCOUNT
}

const flowToDownloadUrlMap: { [key: string]: string } = {
  [Flows.ONBOARDING]: RouteNames.DOWNLOAD,
  [Flows.LOGIN]: RouteNames.LOGIN_DOWNLOAD
}

const flowToGoToEventsMap: { [key: string]: { [key: string]: string } } = {
  [Flows.ONBOARDING]: GO_TO_ONBOARDING_EVENTS_MAP,
  [Flows.LOGIN]: GO_TO_LOGIN_EVENTS_MAP
}

const flowToCommitEventsMap: { [key: string]: { [key: string]: string } } = {
  [Flows.ONBOARDING]: COMMIT_ONBOARDING_EVENTS_MAP,
  [Flows.LOGIN]: COMMIT_LOGIN_EVENTS_MAP
}

const flowGoBackToEventsMap: { [key: string]: { [key: string]: string } } = {
  [Flows.ONBOARDING]: GO_BACK_TO_ONBOARDING_EVENTS_MAP,
  [Flows.LOGIN]: GO_BACK_TO_LOGIN_EVENTS_MAP
}

const flowToSkipEventsMap: { [key: string]: { [key: string]: string } } = {
  [Flows.ONBOARDING]: SKIP_ONBOARDING_EVENTS_MAP,
  [Flows.LOGIN]: {}
}
