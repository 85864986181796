import { produce } from 'immer'
import { BodyFastAction } from '.'
import { ActionType } from './ActionTypes'
import { User as AuthUser } from 'firebase/auth'

export interface LoginState {
  isLoggedIn: boolean
  user: IUser | null
}

export interface IAuthStateChangedAction { type: ActionType.AUTH_STATE_CHANGED, authData: AuthUser | null }
export interface ILoginSuccessAction { type: ActionType.LOGIN_SUCCESS }
export interface ILogoutSuccessAction { type: ActionType.LOGOUT_SUCCESS }

export type LoginAction =
  | IAuthStateChangedAction
  | ILoginSuccessAction
  | ILogoutSuccessAction

const INITIAL_STATE: LoginState = {
  isLoggedIn: false,
  user: null
}

export const LoginReducer = (state: LoginState = INITIAL_STATE, action: BodyFastAction): LoginState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionType.LOGOUT_SUCCESS:
        return INITIAL_STATE

      case ActionType.UPDATE_USER_SUCCESS: {
        draft.user = action.user
        draft.isLoggedIn = !!action.user
        break
      }

      default:
        break
    }
  })
}
