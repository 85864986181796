/* eslint-disable yield-star-spacing */
/* eslint-disable generator-star-spacing */
import * as Sentry from '@sentry/react'
import { getRecurringAndTrialId, getSanitizedSearchParams, getSearchParams, getSearchParamsString, getUserLanguage, isUserSubscribed } from 'Lib'
import { AnalyticEvents } from 'Lib/Constants'
import { getDownloadUrl, getPaymentSuccessUrl, getPaymentUrl, getUpgradeAccountUrl } from 'Lib/EventsUtils'
import { convertToFirebaseFormat, removeUndefined } from 'Lib/SelectionsUtils'
import { isPurchaseAvailable } from 'Lib/StripeUtils'
import {
  ActionType,
  IAnonymousSignInRequestAction,
  ICheckEmailExistsRequestAction,
  ICreateStripeSessionAction,
  ILogoutRequestAction,
  IRegisterWithEmailAndPassword,
  IRegisterWithGoogleRequestAction,
  IResetPasswordRequestAction,
  ISaveOnboardingDataRequestAction,
  ISignInWithEmailRequestAction as ISignInWithEmailAndPasswordAction,
  ISignInWithTokenAction as ISignInWithFutokenAction,
  ISignInWithGoogleOneTapAction,
  ISignInWithGoogleRequestAction,
  ISignUpWithEmailRequestAction,
  IUpdateUserChangesetRequestAction,
  IUpdateUserRequestAction,
  IUpdateUserSuccessAction,
  IUpgradeUserWithGoogleRequestAction,
  Selectors
} from 'Reducers'
import { RouteNames } from 'RouteNames'
import { waitForLogin, waitForStartup } from 'Sagas/HelperSagas'
import { ApiService, IFirebaseService, createStripeCheckoutSession } from 'Services'
import { push, replace } from 'connected-react-router'
import { FirebaseError } from 'firebase/app'
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { delay, race } from 'redux-saga/effects'
import { firebaseServicePromise } from 'store'
import { all, call, put, select, take, takeLatest } from 'typed-redux-saga'
import { Selections } from 'types/onboardingTypes'

/**
 * This saga gets called not only when the user is updated
 * but also when there is a successful sign-in event
 */
function* updateUserSuccessSaga({
  user
}: IUpdateUserSuccessAction) {
  yield call(updateAnalyticsProperties, user)
}

function* updateAnalyticsProperties(user: IUser) {
  const firebaseService: IFirebaseService = yield firebaseServicePromise

  yield call(firebaseService.updateAnalyticsProperty, 'created', user.created)
  yield call(firebaseService.updateAnalyticsProperty, 'createdISOWeek', user.created)

  yield call(firebaseService.updateAnalyticsProperty, 'gender', user.personal?.gender)
  yield call(firebaseService.updateAnalyticsProperty, 'weight', user.stats?.weight?.[0]?.value)
}

function* anonymousSignInSaga({ newsletterAccepted, resolve, reject }: IAnonymousSignInRequestAction) {
  const isUserLoggedIn: boolean = yield select(Selectors.isLoggedIn)

  // We don't need to login again if the user is already logged
  // eg. by navigating back from the payment/registration to plan-is-ready
  if (isUserLoggedIn) {
    yield call(resolve)
    return
  }

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  yield put({ type: ActionType.SET_APP_BLOCKED })

  try {
    const { tokenId } = yield call(firebaseService.anonymousSignIn)

    localStorage.setItem('tokenId', tokenId)
  } catch (error) {
    yield call(logEvent, AnalyticEvents.ANONYMOUS_SIGN_UP_FAIL, error)
    yield put({ type: ActionType.SET_APP_UNBLOCKED })
    yield call(reject, error)
    return
  }

  // Wait until user callback from firebase and we have the user in our store
  yield take(ActionType.UPDATE_USER_SUCCESS)

  yield call(logEvent, AnalyticEvents.SIGN_UP_SUCCESS, { newsletterConfirmed: newsletterAccepted })

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
  yield call(resolve)
}

function* signUpWithEmailSaga({ email, password, onSuccess, onError }: ISignUpWithEmailRequestAction) {
  const firebaseService: IFirebaseService = yield firebaseServicePromise

  try {
    yield put({ type: ActionType.SET_APP_BLOCKED })
    yield call(firebaseService.createUserWithEmailAndPassword, email, password)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)
    yield call(logEvent, AnalyticEvents.SIGN_UP_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    yield call(logEvent, AnalyticEvents.SIGN_UP_FAIL, e)

    yield call(onError, e)
  }
  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* signInWithEmailAndPasswordSaga({ email, password, onSuccess, onError }: ISignInWithEmailAndPasswordAction) {
  const firebaseService: IFirebaseService = yield firebaseServicePromise

  try {
    yield put({ type: ActionType.SET_APP_BLOCKED })

    const isLoggedIn: IUser = yield select(Selectors.isLoggedIn)
    if (isLoggedIn) {
      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(firebaseService.loginWithEmailAndPassword, email, password)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)
    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_EMAIL_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_EMAIL_FAIL, e)

    yield call(onError, e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* signInWithGoogleOneTapSaga({ idToken, onSuccess, onError }: ISignInWithGoogleOneTapAction) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  try {
    // logout the current user if it is not anonymous
    const currentUser: IUser = yield select(Selectors.getUser)

    if (currentUser?.providerData && !currentUser.providerData.isAnonymous) {
      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(firebaseService.signInWithGoogleOneTap, idToken)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_ONE_TAP)

    yield call(onSuccess)
  } catch (e) {
    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_ONE_TAP_FAIL)

    yield call(onError, e)
  }
  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* signInWithGoogleSaga({ onSuccess, onError }: ISignInWithGoogleRequestAction) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')

  try {
    // logout the current user if it is not anonymous
    const currentUser: IUser = yield select(Selectors.getUser)
    if (currentUser?.providerData && !currentUser.providerData.isAnonymous) {
      yield call(logEvent, AnalyticEvents.USER_ALREADY_LOGGED_IN)

      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_REQUEST)

    yield call(firebaseService.signInWithProvider, provider)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    if (e.code === 'auth/popup-closed-by-user' || e.code === 'auth/cancelled-popup-request') {
      yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_CLOSED_BY_USER, e)
      yield put({ type: ActionType.SET_APP_UNBLOCKED })
      return
    }

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_FAIL)
    yield call(onError, e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* registerWithGoogleSaga({ onSuccess, onError }: IRegisterWithGoogleRequestAction) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')

  try {
    // logout the current user if it is not anonymous
    const currentUser: IUser = yield select(Selectors.getUser)
    if (currentUser?.providerData && !currentUser.providerData.isAnonymous) {
      yield call(logEvent, AnalyticEvents.USER_ALREADY_LOGGED_IN)

      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(logEvent, AnalyticEvents.REGISTER_WITH_GOOGLE_REQUEST)

    yield call(firebaseService.createUserWithProvider, provider)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)

    yield call(logEvent, AnalyticEvents.REGISTER_WITH_GOOGLE_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    if (e.code === 'auth/popup-closed-by-user' || e.code === 'auth/cancelled-popup-request') {
      yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_CLOSED_BY_USER, e)
      yield put({ type: ActionType.SET_APP_UNBLOCKED })
      return
    }

    yield call(logEvent, AnalyticEvents.REGISTER_WITH_GOOGLE_FAIL)
    yield call(onError, e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* upgradeUserWithGoogleSaga({ onSuccess, onError }: IUpgradeUserWithGoogleRequestAction) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')

  try {
    // logout the current user if it is not anonymous
    const currentUser: IUser = yield select(Selectors.getUser)
    if (currentUser?.providerData && !currentUser.providerData.isAnonymous) {
      yield call(logEvent, AnalyticEvents.USER_ALREADY_LOGGED_IN)

      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(logEvent, AnalyticEvents.UPGRADE_USER_WITH_GOOGLE_REQUEST)

    yield call(firebaseService.linkUserWithProvider, provider)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)

    yield call(logEvent, AnalyticEvents.UPGRADE_USER_WITH_GOOGLE_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    if (e.code === 'auth/popup-closed-by-user' || e.code === 'auth/cancelled-popup-request') {
      yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_GOOGLE_CLOSED_BY_USER, e)
      yield put({ type: ActionType.SET_APP_UNBLOCKED })
      return
    }

    yield call(logEvent, AnalyticEvents.UPGRADE_USER_WITH_GOOGLE_FAIL)
    yield call(onError, e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* signInWithAppleSaga({ onSuccess, onError }: ISignInWithGoogleOneTapAction) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  const provider = new OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')

  try {
    // logout the current user if it is not anonymous
    const currentUser: IUser = yield select(Selectors.getUser)
    if (currentUser && !currentUser.providerData.isAnonymous) {
      yield call(logEvent, AnalyticEvents.USER_ALREADY_LOGGED_IN)

      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_APPLE_REQUEST)

    yield call(firebaseService.signInWithProvider, provider)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_APPLE_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    if (e.code === 'auth/popup-closed-by-user' || e.code === 'auth/cancelled-popup-request') {
      yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_APPLE_CLOSED_BY_USER, e)
      yield put({ type: ActionType.SET_APP_UNBLOCKED })
      return
    }

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_APPLE_FAIL)
    yield call(onError, e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* registerWithAppleSaga({ onSuccess, onError }: ISignInWithGoogleOneTapAction) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  const provider = new OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')

  try {
    // logout the current user if it is not anonymous
    const currentUser: IUser = yield select(Selectors.getUser)
    if (currentUser && !currentUser.providerData.isAnonymous) {
      yield call(logEvent, AnalyticEvents.USER_ALREADY_LOGGED_IN)

      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_APPLE_REQUEST)

    yield call(firebaseService.createUserWithProvider, provider)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)

    yield call(logEvent, AnalyticEvents.REGISTER_WITH_APPLE_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    if (e.code === 'auth/popup-closed-by-user' || e.code === 'auth/cancelled-popup-request') {
      yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_APPLE_CLOSED_BY_USER, e)
      yield put({ type: ActionType.SET_APP_UNBLOCKED })
      return
    }

    yield call(logEvent, AnalyticEvents.REGISTER_WITH_APPLE_FAIL, e)
    yield call(onError, e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* upgradeUserWithAppleSaga({ onSuccess, onError }: ISignInWithGoogleOneTapAction) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  const firebaseService: IFirebaseService = yield firebaseServicePromise

  const provider = new OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')

  try {
    // logout the current user if it is not anonymous
    const currentUser: IUser = yield select(Selectors.getUser)
    if (currentUser && !currentUser.providerData.isAnonymous) {
      yield call(logEvent, AnalyticEvents.USER_ALREADY_LOGGED_IN)

      yield put({ type: ActionType.LOG_OUT_REQUEST })

      // if something goes wrong with the logout we log it
      // but the user still can perform a login
      yield race({
        logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
        logoutError: take(ActionType.LOGOUT_FAIL)
      })
    }

    yield call(logEvent, AnalyticEvents.UPGRADE_WITH_APPLE_REQUEST)

    yield call(firebaseService.linkUserWithProvider, provider)

    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(updateOnboardingActiveFlagSaga)

    yield call(logEvent, AnalyticEvents.UPGRADE_WITH_APPLE_SUCCESS)

    yield call(onSuccess)
  } catch (e) {
    if (e.code === 'auth/popup-closed-by-user' || e.code === 'auth/cancelled-popup-request') {
      yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_APPLE_CLOSED_BY_USER, e)
      yield put({ type: ActionType.SET_APP_UNBLOCKED })
      return
    }

    yield call(logEvent, AnalyticEvents.UPGRADE_WITH_APPLE_FAIL, e)
    yield call(onError, e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* updateUserRequestSaga({ user }: IUpdateUserRequestAction) {
  const oldUser: IUser = yield select(Selectors.getUser)
  const firebaseService: IFirebaseService = yield firebaseServicePromise

  yield call(firebaseService.updateUser, { ...oldUser, ...user })
}

function* updateUserChangesetSaga({ changeset }: IUpdateUserChangesetRequestAction) {
  const firebaseService: IFirebaseService = yield firebaseServicePromise

  yield call(firebaseService.updateUser, changeset)
}

function* saveOnboardingDataRequestSaga({ onboardingData, newsletterAccepted, email, resolve, reject }: ISaveOnboardingDataRequestAction) {
  try {
    const oldUser: IUser = yield select(Selectors.getUser)

    const convertedOnboardingData: Selections = convertToFirebaseFormat(onboardingData)
    const cleanedData: Selections = removeUndefined(convertedOnboardingData)

    const oldData = {
      newsletter: oldUser.newsletter,
      newsletterEmail: oldUser?.newsletterEmail,
      personal: oldUser.personal,
      stats: oldUser.stats
    }

    const changeset = {
      newsletter: newsletterAccepted,
      newsletterEmail: email,
      ...cleanedData
    }

    // If the data is the same there is no need to perform requests
    if (isEqual(oldData, changeset)) {
      yield call(resolve)
      return
    }

    yield put({ type: ActionType.SET_APP_BLOCKED })

    yield put({
      type: ActionType.UPDATE_USER_CHANGESET_REQUEST,
      changeset: changeset
    })

    // Wait until user callback from firebase and we have the user in our store
    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(logEvent, AnalyticEvents.ONBOARDING_DATA_SAVE_SUCCESS)
  } catch (e) {
    yield call(logEvent, AnalyticEvents.ONBOARDING_DATA_SAVE_FAIL, e)
    yield put({ type: ActionType.SET_APP_UNBLOCKED })
    yield call(reject, e)
    return
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
  yield call(resolve)
}

/**
 * Called when user has correctly paid for a subscription
 * Resolves the purchase event and clears the URL so that the event is not duplicated
 * and navigates to the correct route
 */
function* resolvePurchaseSaga() {
  // First wait until the dust of initialization has settled down
  yield call(waitForStartup)
  yield call(waitForLogin)

  const searchParams = getSearchParams()

  const stripeSessionId = searchParams.get('session_id')
  const subscriptionId = searchParams.get('subscriptionId')
  const source = searchParams.get('source')
  const sanitizedSearchParams = getSanitizedSearchParams()
  const fbc = sanitizedSearchParams.get('fbc')
  const fbp = sanitizedSearchParams.get('fbp')
  const twclid = sanitizedSearchParams.get('twclid')

  let subscription: IStripeSubscription | IPaypalSubscription
  let type: 'stripe' | 'paypal'

  const paymentPath = getPaymentUrl()

  yield delay(1000)

  if (source === 'elements') {
    try {
      if (!subscriptionId) {
        throw new Error('Sub id not available for stripe elements purchase')
      }
      const subscriptionRes: { subscription: IStripeSubscription } = yield call(ApiService.fetchStripeSubscriptionViaId, subscriptionId)
      subscription = subscriptionRes.subscription
      type = 'stripe'
    } catch (e) {
      console.error('cannot resolve stripe elements purchase saga, user has no subscription!')
      const subscriptionError = new Error('Trying to resolve elements purchase without subscription')
      Sentry.captureException(subscriptionError)
      yield call(logEvent, AnalyticEvents.RESOLVE_PURCHASE_NO_STRIPE_SUBSCRIPTION)
      yield call(logEvent, AnalyticEvents.RESOLVE_PURCHASE_SYNC_FAILED)

      const searchParamsString = getSearchParamsString()
      yield put(push(`${paymentPath}${searchParamsString}`))
      return
    }
  } else if (stripeSessionId) {
    try {
      const subscriptionRes: { subscription: IStripeSubscription } = yield call(ApiService.fetchSubscriptionViaSession, stripeSessionId)
      subscription = subscriptionRes.subscription
      type = 'stripe'
    } catch (e) {
      console.error('cannot resolve stripe purchase saga, user has no subscription!')
      const subscriptionError = new Error('Trying to resolve purchase without subscription')
      Sentry.captureException(subscriptionError)
      yield call(logEvent, AnalyticEvents.RESOLVE_PURCHASE_NO_STRIPE_SUBSCRIPTION)
      yield call(logEvent, AnalyticEvents.RESOLVE_PURCHASE_SYNC_FAILED)
      yield put(push(paymentPath))
      return
    }
  } else {
    try {
      if (!subscriptionId) {
        throw new Error('Sub id not available for paypal purchase')
      }

      const subscriptionRes: { subscription: IStripeSubscription | IPaypalSubscription, type: 'paypal' } = yield call(ApiService.fetchPaypalSubscriptionViaId, subscriptionId)
      subscription = subscriptionRes.subscription
      type = 'paypal'
    } catch (e) {
      console.error('cannot resolve paypal purchase saga, user has no subscription!')
      const subscriptionError = new Error('Trying to resolve purchase without subscription')
      Sentry.captureException(subscriptionError)
      yield call(logEvent, AnalyticEvents.RESOLVE_PURCHASE_NO_PAYPAL_SUBSCRIPTION)
      yield call(logEvent, AnalyticEvents.RESOLVE_PURCHASE_SYNC_FAILED)
      yield put(push(paymentPath))
      return
    }
  }

  let saleInfo
  if (type === 'stripe') {
    const stripeSubscription = subscription as IStripeSubscription
    const discountPercentage = Number(stripeSubscription.metadata.discountPercentage)
    const originalTrialPriceStr = stripeSubscription.metadata.originalTrialPrice

    let saleValue
    if (originalTrialPriceStr) {
      saleValue = Number(originalTrialPriceStr) * (discountPercentage / 100) / 100
    } else {
      saleValue = discountPercentage ? stripeSubscription.plan.amount * (discountPercentage / 100) / 100 : stripeSubscription.plan.amount / 100
    }

    const currency = stripeSubscription.plan.currency.toUpperCase()

    saleInfo = {
      purchaseId: stripeSubscription.id,
      value: saleValue,
      currency,
      pricingId: stripeSubscription.plan.id,
      customerId: stripeSubscription.customer,
      affiliation: stripeSubscription.affiliation ?? 'stripe',
      fbc: fbc,
      fbp: fbp,
      twclid: twclid
    }
  } else {
    const paypalSubscription = subscription as IPaypalSubscription

    const saleValue: number = parseFloat(paypalSubscription.billing_info.last_payment.amount.value)
    const currency = paypalSubscription.billing_info.last_payment.amount.currency_code

    saleInfo = {
      purchaseId: paypalSubscription.id,
      value: saleValue,
      currency,
      pricingId: paypalSubscription.plan_id,
      customerId: paypalSubscription.subscriber.payer_id,
      affiliation: 'paypal',
      fbc: fbc,
      fbp: fbp,
      twclid: twclid
    }
  }

  if (searchParams.has('send_event')) {
    searchParams.delete('send_event')

    yield put(replace({ search: searchParams.toString() }))

    yield call(logEvent, AnalyticEvents.PURCHASE_SUCCESS, saleInfo)
  }

  const isRegisteredUser: boolean = yield select(Selectors.isRegisteredUser)

  const registrationUrl: string = yield call(getUpgradeAccountUrl)
  const downloadUrl: string = yield call(getDownloadUrl)

  const successEndpoint = isRegisteredUser
    ? downloadUrl
    : registrationUrl

  yield put(push(successEndpoint))
}

function* signInWithFutokenSaga({ futoken, onError }: ISignInWithFutokenAction) {
  yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_TOKEN_REQUEST)

  const firebaseService: IFirebaseService = yield firebaseServicePromise
  const isLoggedIn: ReturnType<typeof Selectors.isLoggedIn> = yield select(Selectors.isLoggedIn)

  // log out current user to avoid funny errors and broken states
  if (isLoggedIn) {
    yield put({ type: ActionType.LOG_OUT_REQUEST })

    // if something goes wrong with the logout we log it
    // but the user still can perform a login
    yield race({
      logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
      logoutError: take(ActionType.LOGOUT_FAIL)
    })
  }

  const { error: jwtError, token } = yield call(ApiService.fetchJWT, futoken)

  if (jwtError) {
    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_TOKEN_FAIL, jwtError)
    yield call(onError, jwtError)
    return
  }

  try {
    yield call(firebaseService.signInWithToken, token)
  } catch (error) {
    yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_TOKEN_FAIL, error)
    yield call(onError, error)
    return
  }

  // Wait until user callback from firebase and we have the user in our store
  yield take(ActionType.UPDATE_USER_SUCCESS)

  yield put({ type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE })

  yield call(logEvent, AnalyticEvents.SIGN_IN_WITH_TOKEN_SUCCESS)
}

function* updateOnboardingActiveFlagSaga() {
  const user: IUser = yield select(Selectors.getUser)

  const hasPersonalData = user.personal !== undefined
  const isOnboardingActive = user.onboardingActive
  const isWebUser = user.isWebUser

  // we want to guarantee that the user has the onboardingActive flag set to true
  // so we can show the onboarding screen on the app in case the user has not
  // completed it on the web or the user just signed up without performing the onboarding
  if (!hasPersonalData && !isOnboardingActive && isWebUser) {
    yield put({ type: ActionType.UPDATE_USER_CHANGESET_REQUEST, changeset: { onboardingActive: true } })

    yield take(ActionType.UPDATE_USER_SUCCESS)
  }
}

function* logoutRequestSaga({ nextRouteName }: ILogoutRequestAction) {
  const isUserLoggedIn: boolean = yield select(Selectors.isLoggedIn)

  if (isUserLoggedIn) {
    try {
      const firebaseService: IFirebaseService = yield firebaseServicePromise
      yield call(logEvent, AnalyticEvents.LOGOUT)
      yield call(firebaseService.logout)
    } catch (error) {
      Sentry.captureException(error)
      yield call(logEvent, AnalyticEvents.LOGOUT_FAIL, error)
      yield put({ type: ActionType.LOGOUT_FAIL })
    }
  } else {
    yield call(logEvent, AnalyticEvents.LOGOUT_FAIL, { message: 'user not logged in' })
    yield put({ type: ActionType.LOGOUT_FAIL })
  }

  if (nextRouteName) {
    yield put(push(nextRouteName))
  }
}

function* checkEmailExistsSaga({ email, resolve }: ICheckEmailExistsRequestAction) {
  try {
    yield put({ type: ActionType.SET_APP_BLOCKED })
    const exists = (yield call(ApiService.checkEmailExists, email)) as boolean
    yield call(resolve, exists)
  } catch (e) {
    Sentry.captureException(e)
    yield call(resolve, false)
  }
  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* resetPasswordSaga({ email, onError, onSuccess }: IResetPasswordRequestAction) {
  try {
    const firebaseService: IFirebaseService = yield firebaseServicePromise
    yield call(firebaseService.resetPassword, email)

    yield call(onSuccess)
  } catch (e) {
    Sentry.captureException(e)
    if (e.code === 'auth/user-not-found') {
      yield call(onError, t('FirebaseError.auth/user-not-found'))
    } else {
      yield call(onError, t('resetPasswordModal.errorMessage'))
    }
  }
}

function* createStripeSessionSaga({ packages, selectedPackage, onSuccess, onError }: ICreateStripeSessionAction) {
  yield call(logEvent, AnalyticEvents.START_CHECKOUT, { channel: 'stripe', ...selectedPackage })

  try {
    yield put({ type: ActionType.SET_APP_BLOCKED })
    const lang: string = yield call(getUserLanguage)
    const { recurringPackageId, trialId } = yield call(getRecurringAndTrialId, selectedPackage, selectedPackage.id, packages)
    const gender: IGender | null | undefined = yield select(Selectors.getGender)

    const successEndpoint: string = yield call(getPaymentSuccessUrl)
    const cancelEndpoint: string = yield call(getPaymentUrl)

    const { sessionId }: { sessionId: string } = yield call(createStripeCheckoutSession, recurringPackageId, lang, trialId, !!selectedPackage.discountedPrice, gender, successEndpoint, cancelEndpoint)

    yield call(logEvent, AnalyticEvents.STRIPE_SESSION_CREATION_SUCCESS)
    onSuccess(sessionId)
  } catch (e) {
    yield call(logEvent, AnalyticEvents.STRIPE_SESSION_CREATION_EXCEPTION, { e })
    onError(e)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function* handleNavigatingToPostOnboardingRouteSaga() {
  const currentUser: (IUser | undefined) = yield select(Selectors.getUser)

  if (!currentUser) {
    yield call(logEvent, AnalyticEvents.NAVIGATE_TO_POST_ONBOARDING_ROUTES_FAIL, { message: 'no existing user' })
    yield put(push(RouteNames.LOADING))
    return
  }

  const isRegisteredUser: boolean = yield select(Selectors.isRegisteredUser)

  let hasSubscription = false
  hasSubscription = yield call(isUserSubscribed, currentUser)

  const purchaseAvailable: boolean = yield call(isPurchaseAvailable)

  if (!hasSubscription && !purchaseAvailable) {
    // Track how many users are skipping the upsell screen. See: https://bodyfastworkspace.slack.com/archives/CDAJ9JY6T/p1719394042749919?thread_ts=1719391425.202899&cid=CDAJ9JY6T
    yield call(logEvent, AnalyticEvents.WEB_UPSELL_SKIPPED)
  }

  if (!hasSubscription && purchaseAvailable) {
    const paymentRoute: string = yield call(getPaymentUrl)
    yield put(push(paymentRoute))
  } else if (!isRegisteredUser) {
    const registrationRoute: string = yield call(getUpgradeAccountUrl)
    yield put(push(registrationRoute))
  } else {
    const downloadRoute: string = yield call(getDownloadUrl)
    yield put(push(downloadRoute, { hasOldSubscription: hasSubscription }))
  }
}

function* registerWithEmailAndPasswordSaga({ email, password, name, onError }: IRegisterWithEmailAndPassword) {
  try {
    yield call(logEvent, AnalyticEvents.WEB_REGISTER_WITH_EMAIL_AND_PASSWORD_REQUEST)

    yield put({ type: ActionType.SET_APP_BLOCKED })

    yield put({ type: ActionType.LOG_OUT_REQUEST })
    yield race({
      logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
      logoutError: take(ActionType.LOGOUT_FAIL)
    })

    yield put({
      type: ActionType.SIGN_UP_WITH_EMAIL_REQUEST,
      email,
      password,
      onSuccess: () => { },
      onError: (error: FirebaseError) => {
        onError(error)
      }
    })
    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield put({ type: ActionType.UPDATE_USER_CHANGESET_REQUEST, changeset: { name } })
    yield take(ActionType.UPDATE_USER_SUCCESS)

    yield call(logEvent, AnalyticEvents.WEB_REGISTER_WITH_EMAIL_AND_PASSWORD_SUCCESS)
    yield put({ type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE })
  } catch (e) {
    yield call(logEvent, AnalyticEvents.WEB_REGISTER_WITH_EMAIL_AND_PASSWORD_FAIL, e)
    yield call(onError, e)
  }
}

export function* AppSagas() {
  yield all([
    takeLatest(ActionType.SAVE_ONBOARDING_DATA_REQUEST, saveOnboardingDataRequestSaga),
    takeLatest(ActionType.LOG_OUT_REQUEST, logoutRequestSaga),
    takeLatest(ActionType.UPDATE_USER_SUCCESS, updateUserSuccessSaga),
    takeLatest(ActionType.UPDATE_USER_REQUEST, updateUserRequestSaga),
    takeLatest(ActionType.UPDATE_USER_CHANGESET_REQUEST, updateUserChangesetSaga),
    takeLatest(ActionType.ANONYMOUS_SIGN_IN_REQUEST, anonymousSignInSaga),
    takeLatest(ActionType.SIGN_IN_WITH_FUTOKEN_REQUEST, signInWithFutokenSaga),
    takeLatest(ActionType.RESOLVE_PURCHASE, resolvePurchaseSaga),
    takeLatest(ActionType.SIGN_UP_WITH_EMAIL_REQUEST, signUpWithEmailSaga),
    takeLatest(ActionType.SIGN_IN_WITH_GOOGLE_ONE_TAP, signInWithGoogleOneTapSaga),
    takeLatest(ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST, signInWithEmailAndPasswordSaga),
    takeLatest(ActionType.SIGN_IN_WITH_GOOGLE_REQUEST, signInWithGoogleSaga),
    takeLatest(ActionType.SIGN_IN_WITH_APPLE_REQUEST, signInWithAppleSaga),
    takeLatest(ActionType.REGISTER_WITH_GOOGLE_REQUEST, registerWithGoogleSaga),
    takeLatest(ActionType.REGISTER_WITH_APPLE_REQUEST, registerWithAppleSaga),
    takeLatest(ActionType.UPGRADE_USER_WITH_GOOGLE_REQUEST, upgradeUserWithGoogleSaga),
    takeLatest(ActionType.UPGRADE_USER_WITH_APPLE_REQUEST, upgradeUserWithAppleSaga),
    takeLatest(ActionType.CHECK_EMAIL_EXISTS_REQUEST, checkEmailExistsSaga),
    takeLatest(ActionType.RESET_PASSWORD_REQUEST, resetPasswordSaga),
    takeLatest(ActionType.CREATE_STRIPE_SESSION_REQUEST, createStripeSessionSaga),
    takeLatest(ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE, handleNavigatingToPostOnboardingRouteSaga),
    takeLatest(ActionType.REGISTER_WITH_EMAIL_AND_PASSWORD, registerWithEmailAndPasswordSaga)
  ])
}
