import { TFunction } from 'i18next'
import { Step } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getCurrentBodyType(t: TFunction, gender?: IGender): Step {
  const curentBodyType = 'current_body_type'

  const overweight = 'overweight'
  const slightlyOverweight = 'slightly_overweight'
  const midsized = 'midsized'
  const thin = 'thin'

  return {
    id: curentBodyType,
    type: 'single-select',
    wordings: {
      title: t(`${curentBodyType}.${title}`)
    },
    options: [
      {
        id: overweight,
        label: t(`${curentBodyType}.${options}.${overweight}`),
        emoji: '🟣'
      },
      {
        id: slightlyOverweight,
        label: t(`${curentBodyType}.${options}.${slightlyOverweight}`),
        emoji: '🟠'
      },
      {
        id: midsized,
        label: t(`${curentBodyType}.${options}.${midsized}`),
        emoji: '🟢'
      },
      {
        id: thin,
        label: t(`${curentBodyType}.${options}.${thin}`),
        emoji: '🟡'
      }
    ]
  }
}
