import { useAppState } from 'AppContextProvider'
import { ActionType, Selectors } from 'Reducers'
import { RouteNames, ONBOARDING_ROUTES_BY_STEP_ID } from 'RouteNames'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'
import { FirebaseError } from '@firebase/util'
import { useEffect, useState } from 'react'
import { getUid } from 'Services'
import { showToast } from 'Components/Toast'

const DevelopmentPanelWrapper = styled.div`
  @media(max-width: 768px) {
    flex-direction: column;
    gap: 100px;
  }
`

const ButtonsWrapper = styled.div`
  position: fixed;
  bottom: 50%;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;

  @media(max-width: 768px) {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
  }
`

const Button = styled.button`
  background-color: #b7d7ff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`

const Text = styled.p`
  align-self: center;
`

const selections = {
  fasting_knowledge: 'beginner',
  goals: ['lose_weight'],
  perfect_weight: { inKg: { unit: 'cm/kg', value: 60 }, original: { unit: 'cm/kg', value: 60 } },
  weight: { inKg: { unit: 'cm/kg', value: 70 }, original: { unit: 'cm/kg', value: 70 } },
  height: { inCm: { value: 170 } },
  gender: 'm',
  age: 25
}

const DevelopmentPanel: React.FC = ({ children }) => {
  const history = useHistory()
  const { appState, setAppState } = useAppState()
  const dispatch = useDispatch()
  const user = useSelector(Selectors.getUser)
  const [uid, setUid] = useState<string | null>(getUid())

  useEffect(() => {
    setUid(getUid())
  }, [user])

  const signInUserAndSaveData = (resolve: () => void) => {
    dispatch({
      type: ActionType.ANONYMOUS_SIGN_IN_REQUEST,
      newsletterAccepted: true, // Only for logging
      reject: (e: FirebaseError) => {
        const errorMessage = t(`FirebaseError.${e.code}`, { ns: 'translation', defaultValue: t('FirebaseError.auth/unknown', { ns: 'translation' }) })

        showToast('error', errorMessage)
      },
      resolve: () => {
        saveOnboardingData(resolve)
      }
    })
  }

  const saveOnboardingData = (resolve: () => void) => {
    dispatch({
      type: ActionType.SAVE_ONBOARDING_DATA_REQUEST,
      onboardingData: { ...selections },
      newsletterAccepted: true,
      email: `test+${Math.round(Math.random() * 1000000)}@test.com`,
      resolve: resolve,
      reject: (e: FirebaseError) => {
        const errorMessage = t(`FirebaseError.${e.code}`, { ns: 'translation', defaultValue: t('FirebaseError.auth/unknown', { ns: 'translation' }) })

        showToast('error', errorMessage)
      }
    })
  }

  const skipToPayment = async () => {
    setAppState((prevState) => ({
      ...prevState,
      selections: selections
    }))

    signInUserAndSaveData(() => {
      dispatch({
        type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE
      })
    })
  }

  const skipToEmailInput = () => {
    setAppState((prevState) => ({
      ...prevState,
      selections
    }))

    history.push(ONBOARDING_ROUTES_BY_STEP_ID.email)
  }

  const skipToRegistration = async () => {
    signInUserAndSaveData(() => {
      history.push(`${RouteNames.UPGRADE_ACCOUNT}`)
    })
  }

  const logout = async () => {
    setAppState((prevState) => ({
      ...prevState,
      selections: undefined
    }))
    dispatch({ type: ActionType.LOG_OUT_REQUEST, nextRouteName: RouteNames.LOADING })
  }

  return (
    <DevelopmentPanelWrapper>
      {children}
      <ButtonsWrapper>
        <Button onClick={skipToPayment} >payment</Button>
        <Button onClick={skipToEmailInput} >email (create user)</Button>
        <Button onClick={skipToRegistration} >registration</Button>
        <Button onClick={logout} >logout</Button>
        <Text>{uid ?? 'No user'}</Text>
        <Text>{`v${process.env.REACT_APP_VERSION}`}</Text>
      </ButtonsWrapper >
    </DevelopmentPanelWrapper>
  )
}

export default DevelopmentPanel
