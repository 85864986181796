export const mapFAQLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/faq'
    case 'en':
      return 'https://www.bodyfast.app/en/faq'
    case 'fr':
      return 'https://www.bodyfast.app/fr/faq'
    case 'es':
      return 'https://www.bodyfast.app/es/faq'
    case 'pt':
      return 'https://www.bodyfast.app/pt/faq'
    case 'it':
      return 'https://www.bodyfast.app/it/faq'
    default:
      throw new Error(`FAQ link not found for language ${lang}`)
  }
}

export const mapHelpdeskLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://help.bodyfast.app/de/support/solutions'
    case 'en':
      return 'https://help.bodyfast.app/en/support/solutions'
    case 'fr':
      return 'https://help.bodyfast.app/fr/support/solutions'
    case 'es':
      return 'https://help.bodyfast.app/es/support/solutions'
    case 'pt':
      return 'https://help.bodyfast.app/pt-PT/support/solutions' // pt-PT is the correct language code for Portuguese
    case 'it':
      return 'https://help.bodyfast.app/it/support/solutions'
    default:
      throw new Error(`Helpdesk link not found for language ${lang}`)
  }
}

export const mapPrivacyPolicyLink = (lang: string) => {
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  return `${baseUrl}/privacy`
}

export const mapCookiePolicyLink = (lang: string) => {
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  return `${baseUrl}/cookie-policy`
}

export const mapTermsOfUseLink = (lang: string) => {
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  return `${baseUrl}/terms`
}

export const mapRefundPolicyLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/Refund'
    case 'en':
      return 'https://www.bodyfast.app/en/Refund'
    case 'fr':
      return 'https://www.bodyfast.app/fr/Refund'
    case 'es':
      return 'https://www.bodyfast.app/es/Refund'
    case 'pt':
      return 'https://www.bodyfast.app/pt/Refund'
    case 'it':
      return 'https://www.bodyfast.app/it/Refund'
    default:
      throw new Error(`Refund Policy not found for language ${lang}`)
  }
}
