export enum ActionType {
  REHYDRATE = 'persist/REHYDRATE',
  START_UP = 'app/START_UP',
  SYNC_PARAMETERS = 'app/SYNC_PARAMETERS',
  GET_SEARCH_PARAMETERS = 'app/GET_SEARCH_PARAMETERS',
  START_UP_SUCCESS = 'startup/START_UP_SUCCESS',
  LOADING_FINISHED = 'startup/LOADING_FINISHED',
  AUTH_INITIALIZED = 'startup/AUTH_INITIALIZED',
  STRIPE_INITIALIZED = 'startup/STRIPE_INITIALIZED',
  FIREBASE_INITIALIZED = 'startup/FIREBASE_INITIALIZED',
  FIREBASE_USER_INITIALIZED = 'app/FIREBASE_USER_INITIALIZED',
  UPDATE_USER_SUCCESS = 'login/UPDATE_USER_SUCCESS',
  UPDATE_USER_CHANGESET_REQUEST = 'login/UPDATE_USER_CHANGESET_REQUEST',
  RESOLVE_PURCHASE = 'app/RESOLVE_PURCHASE',
  ANONYMOUS_SIGN_IN_REQUEST = 'login/ANONYMOUS_SIGN_UP_REQUEST',
  UPDATE_USER_REQUEST = 'login/UPDATE_USER_REQUEST',
  LOG_OUT_REQUEST = 'login/LOG_OUT_REQUEST',
  LOGOUT_SUCCESS = 'login/LOGOUT_SUCCESS',
  LOGOUT_FAIL = 'login/LOGOUT_FAIL',
  SAVE_ONBOARDING_DATA_REQUEST = 'app/SAVE_ONBOARDING_DATA_REQUEST',
  SET_APP_BLOCKED = 'app/SET_APP_BLOCKED',
  SET_APP_UNBLOCKED = 'app/SET_APP_UNBLOCKED',
  LOGIN_SUCCESS = 'login/LOGIN_SUCCESS',
  AUTH_STATE_CHANGED = 'login/AUTH_STATE_CHANGED',
  SIGN_UP_WITH_EMAIL_REQUEST = 'login/SIGN_UP_WITH_EMAIL_REQUEST',
  SIGN_IN_WITH_FUTOKEN_REQUEST = 'login/SIGN_IN_WITH_FUTOKEN_REQUEST',
  SIGN_IN_WITH_GOOGLE_ONE_TAP = 'login/SIGN_IN_WITH_GOOGLE_ONE_TAP',
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST = 'login/SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST',
  SIGN_IN_WITH_GOOGLE_REQUEST = 'login/SIGN_IN_WITH_GOOGLE_REQUEST',
  SIGN_IN_WITH_APPLE_REQUEST = 'login/SIGN_IN_WITH_APPLE_REQUEST',
  REGISTER_WITH_GOOGLE_REQUEST = 'login/REGISTER_WITH_GOOGLE_REQUEST',
  REGISTER_WITH_APPLE_REQUEST = 'login/REGISTER_WITH_APPLE_REQUEST',
  UPGRADE_USER_WITH_GOOGLE_REQUEST = 'login/UPGRADE_USER_WITH_GOOGLE_REQUEST',
  UPGRADE_USER_WITH_APPLE_REQUEST = 'login/UPGRADE_USER_WITH_APPLE_REQUEST',
  CHECK_EMAIL_EXISTS_REQUEST = 'login/CHECK_EMAIL_EXISTS_REQUEST',
  RESET_PASSWORD_REQUEST = 'login/RESET_PASSWORD_REQUEST',
  CREATE_STRIPE_SESSION_REQUEST = 'purchase/CREATE_STRIPE_SESSION_REQUEST',
  HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE = 'app/HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE',
  REGISTER_WITH_EMAIL_AND_PASSWORD = 'login/REGISTER_WITH_EMAIL_AND_PASSWORD',
  SET_OFFER_DISCOUNT = 'discount/SET_OFFER_DISCOUNT',
  SET_DISCOUNT_TIME_REMAINING = 'discount/SET_DISCOUNT_TIME_REMAINING',
  FETCH_DISCOUNT_SEARCH_PARAMS = 'discount/FETCH_DISCOUNT_SEARCH_PARAMS',
  SET_DISCOUNT_BANNER_TYPE = 'discount/SET_DISCOUNT_BANNER_TYPE',
  DISCOUNT_TIME_EXPIRED = 'discount/DISCOUNT_TIME_EXPIRED',
  START_DISCOUNT_TIMER = 'discount/START_DISCOUNT_TIMER',
  SET_PAUSE_DISCOUNT_OFFSET = 'discount/SET_PAUSE_DISCOUNT_OFFSET',
  SET_DISCOUNT_STARTED_AT = 'discount/SET_DISCOUNT_STARTED_AT',
  RESET_DISCOUNT_TIMER = 'discount/RESET_DISCOUNT_TIMER',
  TIMER_TICK = 'timer/TICK',
  TIMER_STOP = 'timer/STOP',
  ADD_DEBUG_SECONDS = 'timer/ADD_DEBUG_SECONDS'
}
